import { useState, useEffect, useCallback } from 'react';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Toastify } from '../../components/toastify/Toastify';
import { Container } from '../../components/container/Index';
import { Layout } from '../../components/layout/Index';
import {
  GrayButton,
  PrimaryButton,
  SuccessButton,
} from '../../components/button/Index';

import { Requests } from '../../utils/Http/Index';
import { FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Text } from '../../components/text/Text';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';

const EditAccount = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const [isMounted, setIsMounted] = useState(true); // New state

  const [loading, setLoading] = useState(false);
  const [filled, setFilled] = useState(false);
  const [data, setData] = useState([]);
  const { id } = useParams();

  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();

  //useEffect cleanup function to avoid memory leaks after submitting form
  useEffect(() => {
    setIsMounted(true); // Set to true when the component mounts

    return () => {
      setIsMounted(false); // Set to false when the component unmounts
    };
  }, []);

  // Fetch data
  const fetchSingleMoney = useCallback(async () => {
    try {
      const response = await Requests.AccountingAll.Account.SingleAccounting(
        id
      );

      if (response.status === 200) {
        setLoading(false);
        setData(response.data.data);
        handleFillForm(response.data.data);
      }
    } catch (error) {
      if (error) {
        setLoading(false);
        // setServerError(true)
      }
    }
  }, [id]);

  useEffect(() => {
    fetchSingleMoney();
  }, [fetchSingleMoney]);

  // const fetchSingleMoney = useCallback(async (perPage) => {
  //   setLoading(true);
  //   const response = await Requests.AccountingAll.Account.SingleAccounting(id);
  //   setData(response.data.data);
  //   // setTotalRows(response.data.total);
  //   setLoading(false);
  //   handleFillForm()
  // }, []);

  // useEffect(() => {
  //   fetchSingleMoney(id);
  // }, [fetchSingleMoney,id]);

  const handleFillForm = (data) => {
    setFilled(true);
    setValue('title', data.title);
    setValue('date', moment(data.date).format('YYYY-MM-DD'));
    setValue('amount', data.amount);
    setValue('description', data.description);
  };

  // Submit Form
  const onSubmit = async (data) => {
    // data.dokan_uid = localStorage.getItem('dokanuid');
    try {
      const response = await Requests.AccountingAll.Account.UpdateMoney(
        data,
        id
      );
      if (isMounted) {
        // Check if the component is still mounted before updating state
        if (response.status === 201 || response.status === 200) {
          Toastify.Success(t('Withdraw Updated Successfully'));
          history.push('/dashboard/accounting/account-list');
        } else {
          Toastify.Error(t('Something went wrong'));
        }
      }
    } catch (error) {
      if (isMounted) {
        // Check if the component is still mounted before updating state
        console.error('Error fetching accounts with query:', error);
        setError(true);
      }
    } finally {
      if (isMounted) {
        // Check if the component is still mounted before updating state
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Layout
        page={t('dashboard / Accounting / Edit Money')}
        message={t('Update Money in Accounting.')}
        container='container-fluid'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/employee-management/list'>
              <GrayButton type='button'>
                <ArrowLeft size={15} />
                <span style={{ fontSize: 13 }}>BACK</span>
              </GrayButton>
            </Link>
          </div>
        }
      />

      {/* <Container.Row> */}
      {/* Name */}
      <form onSubmit={handleSubmit(onSubmit)} className='mx-3'>
        <Container.Row>
          <Container.Column className='col-lg-6'>
            <FormGroup>
              {errors.title && errors.title.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.title && errors.title.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Name / Money Source')}
                  <span className='text-danger'> *</span>
                </Text>
              )}

              <input
                type='text'
                name='title'
                className={
                  errors.name
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                placeholder={t('Enter Name / Money Source')}
                {...register('title', {
                  required: t('Name is required'),
                })}
              />
            </FormGroup>
          </Container.Column>

          <Container.Column className='col-lg-6'>
            <FormGroup>
              {errors.amount && errors.amount.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.amount && errors.amount.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Amount')}
                  <span className='text-danger'> *</span>
                </Text>
              )}

              <input
                type='number'
                name='amount'
                className={
                  errors.amount
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                placeholder={t('Enter Amount')}
                {...register('amount', {
                  required: t('Amount is required'),
                })}
              />
            </FormGroup>
          </Container.Column>

          {/* Phone */}
          <Container.Column className='col-lg-6'>
            <FormGroup>
              {errors.date && errors.date.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.date && errors.date.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Date')} <span className='text-danger'> *</span>
                </Text>
              )}
              {filled && (
                <input
                  type='date'
                  name='date'
                  className={
                    errors.date
                      ? 'form-control shadow-none error'
                      : 'form-control shadow-none'
                  }
                  {...register('date', {
                    required: t('Date is required'),
                  })}
                />
              )}
            </FormGroup>
          </Container.Column>
          <Container.Column className='col-lg-12'>
            <FormGroup>
              {errors.description && errors.description.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.description && errors.description.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Description')}
                  {/* <span className="text-danger"> *</span> */}
                </Text>
              )}

              <textarea
                type='text'
                name='description'
                className={
                  errors.description
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                placeholder='Write Description Here...'
                {...register('description')}
              />
            </FormGroup>
          </Container.Column>

          {/* </Container.Row> */}
        </Container.Row>

        <div className=' text-right'>
          <PrimaryButton className='px-4'>
            {/* {loading
                ? creating
                  ? t('Creating ...')
                  : t('Updating ...')
                : creating
                ? t('Update')
                : t('Create')} */}
            Create
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default EditAccount;
