import React, { useCallback, useEffect, useState } from 'react';
import { Edit2, Eye, Plus, Printer, Trash2 } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DangerButton,
  GrayButton,
  SuccessButton,
} from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { Layout, Main } from '../../../components/layout/Index';
import { DeleteModal } from '../../../components/modal/DeleteModal';
import { DataTable } from '../../../components/table/Index';
import { Text } from '../../../components/text/Text';
import { Images } from '../../../utils/Images';

import { NetworkError } from '../../../components/501/NetworkError';

import Barcode from 'react-barcode';
import BrandImage from '../../../assets/logo.png';
import { PrimaryModal } from '../../../components/modal/PrimaryModal';
import { Toastify } from '../../../components/toastify/Toastify';
import { Requests } from '../../../utils/Http/Index';
import { getCurrentDateTime } from '../../../utils/_heplers';

//for pdf
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { FormGroup } from '../../../components/formGroup/FormGroup';
import { size } from 'lodash';
import { SingleSelect } from '../../../components/select/Index';
import { DatePicker } from '../../../components/datePicker/Index';
import moment from 'moment';

const Index = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [date, setDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [categoryData, setCategoryData] = useState([]);
  const [brandData, setBrandData] = useState([]);
  const [supplierData, setSupplierData] = useState([]);
  const [category, setCategory] = useState('');
  const [brand, setBrand] = useState('');
  const [supplier, setSupplier] = useState('');
  const [searchLoading, setsearchLoading] = useState(false);
  const [isDelete, setDelete] = useState({
    value: null,
    show: false,
    loading: false,
  });
  const [serverError, setServerError] = useState(false);
  const [seperatecodemodal, setSeperateCodeModal] = useState({
    value: null,
    show: false,
    loading: false,
  });

  const handleGeneratePDF = () => {
    // jsPDF.API.registerFont("RobotoCondensedBold", RobotoCondensedBold);

    const pdf = new jsPDF();
    const columns = [
      'Name',
      'Quantity',
      'Category',
      'Brand',
      'Purchase Price',
      'Selling Price',
      'Discount Amount',
    ];

    // Calculate the x-coordinate for the brand image to align it to the right
    const imageX = pdf.internal.pageSize.width - 30;

    // Add brand image on the right
    pdf.addImage(BrandImage, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165; // Adjust the value based on your desired spacing
    const textY = 20;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY, 'PRODUCT DATA');
    // Add another small text with a different font size

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10; // Set the font size for the small text
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 6, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns], // Headers
      body: data.map((row) => [
        row.name || 'N/A',
        row.quantity ?? 'N/A',
        row.category ? row.category.name : 'N/A',
        row.brand ? row.brand.name : 'N/A',
        row.purchase_price || 'N/A',
        row.selling_price || 'N/A',
        row.discount_amount || 'N/A',
      ]),
      styles: {
        //   fillColor: [104, 126, 255], // RGB color for blue background
        font: 'RobotoCondensedBold',
        textColor: [17, 35, 90], // RGB color for text (adjust as needed)
        halign: 'center', // Horizontal alignment: 'left', 'center', 'right'
        valign: 'middle', // Vertical alignment: 'top', 'middle', 'bottom'
        fontSize: 12, // Font size for the table content
        cellPadding: 2, // Padding inside each cell
        borderBottom: '1px solid black',
        rowStyles: (rowIndex) => {
          // Borders at the top and bottom for each row
          return {
            borderTop: rowIndex === 0 ? '1px solid black' : 'none',
            borderBottom: '1px solid black',
          };
        },
      },
      headStyles: {
        fillColor: [34, 50, 100], // RGB color for header background
        textColor: [255, 255, 255], // RGB color for header text
        fontSize: 10, // Font size for the header
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Set width for the first column
        1: { cellWidth: 'auto' }, // Set auto width for the second column
        // Add more column styles as needed
      },
    });

    pdf.save('product_data.pdf');
  };

  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response = await Requests.Inventory.Product.DokanProductList(
          page,
          perPage
        );
        if (response && response.status === 200) {
          setData(response.data.data);
          setTotalRows(response.data.meta.total);
        }

        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  const handlePageChange = (page) => fetchData(page);

  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setLoading(true);
      const response = await Requests.Inventory.Product.DokanProductList(
        page,
        newPerPage
      );
      setData(response.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error) {
        setLoading(false);
        setServerError(true);
      }
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  const fetchProductListWithQuery = useCallback(
    async (fromDate, toDate) => {
      setLoading(true);
      const formattedDateFrom = fromDate
        ? moment(fromDate).format('YYYY-MM-DD')
        : '';
      const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
      try {
        const response = await Requests.Inventory.Product.DokanProductList(
          totalRows,
          perPage,
          formattedDateFrom,
          formattedDateTo,
          category,
          brand,
          supplier
        );
        setData(response.data.data);
        setTotalRows(response.data.total);
      } catch (error) {
        // Handle error
        console.error('Error fetching accounts with query:', error);
        // setError(true);
      } finally {
        setLoading(false);
      }
    },
    [date, toDate, perPage, totalRows, category, brand, supplier]
  );

  useEffect(() => {
    if (date || toDate || category || brand || supplier) {
      // If date and toDate are set, fetch with query parameters
      fetchProductListWithQuery(date, toDate, category, brand, supplier);
    } else {
      // Otherwise, fetch without query parameters
      fetchData();
    }
  }, [fetchData, fetchProductListWithQuery]);

  // Handle search
  const handleSearch = async (data) => {
    setsearchLoading(true);
    try {
      const response = await Requests.Inventory.Product.DokanProductSearch(
        data
      );
      if (response.data && response.status === 200) setData(response.data.data);
      setsearchLoading(false);
    } catch (error) {
      if (error) {
        setsearchLoading(false);
        setServerError(true);
      }
    }
  };

  // Handle search suggestion
  const handleSuggestion = async (value) => {
    let data = {
      results: [],
      message: null,
    };
    const response = await Requests.Inventory.Product.DokanProductSearch(value);
    if (response && response.status === 200) {
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];
        data.results.push(element.name);
      }
    } else {
      data.message = 'No results found';
    }

    return data;
  };

  // Handle delete
  const handleDelete = async () => {
    try {
      const response = await Requests.Inventory.Product.DokanProductDelete(
        isDelete.value.uid
      );
      if (response && response.status === 200) {
        Toastify.Success(t('Product deleted successfully.'));
        fetchData();
      }

      setDelete({ loading: false, value: null, show: false });
    } catch (error) {
      if (error) {
        setDelete({ loading: false, value: null, show: false });
        Toastify.Error(t('Failed to delete.'));
      }
    }
  };

  // category data
  const fetchCategories = useCallback(async () => {
    try {
      const response = await Requests.Inventory.Category.CategoryList();
      const data = [];
      for (let i = 0; i < response.data.data.length; i++) {
        data.push({
          value: response.data.data[i].uid,
          label: response.data.data[i].name,
        });
      }
      setCategoryData(data);
    } catch (error) {
      if (error) console.log(error);
    }
  }, []);

  // brand data
  const fetchBrands = useCallback(async () => {
    const response = await Requests.Inventory.Brand.DokanBrandList();
    if (response && response.status === 200) {
      const data = [];
      for (let i = 0; i < response.data.data.length; i++) {
        data.push({
          value: response.data.data[i].uid,
          label: response.data.data[i].name,
        });
      }
      setBrandData(data);
    }
  }, []);

  // supplier data
  const fetchSuppliers = useCallback(async () => {
    try {
      const response = await Requests.Inventory.Supplier.DokanSupplierList();

      const suppliers = response.data.data.map((supplier) => {
        return { label: supplier.name, value: supplier.uid };
      });
      setSupplierData(suppliers);
    } catch (error) {
      if (error) console.log(error);
    }
  }, []);

  // fetch all datas for filter
  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  // datatable custom styles
  const customStyles = {
    rows: {
      style: {
        minHeight: '60px',
      },
    },
  };

  // data columns
  const columns = [
    {
      name: 'Image',
      grow: 0,
      cell: (row) => (
        <img
          height={50}
          width={50}
          alt={row.featured_image}
          src={row.featured_image}
        />
      ),
    },
    {
      name: 'Name',
      selector: (row) => row.name || 'N/A',
      sortable: true,
    },
    {
      name: 'Code',
      selector: (row) =>
        row.product_code
          ? row.product_code
          : row.codes.map((code) => code.code).join(', ') || 'N/A',
      sortable: true,
    },
    {
      name: 'Quantity',
      selector: (row) => row.quantity || '0',
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => (row.category ? row.category.name : 'N/A'),
      sortable: true,
    },
    {
      name: 'Brand',
      selector: (row) => (row.brand ? row.brand.name : 'N/A'),
      sortable: true,
    },
    {
      name: 'Purchase Price',
      selector: (row) => row.purchase_price || 'N/A',
      sortable: true,
    },
    {
      name: 'Sell Price',
      selector: (row) => row.selling_price || 'N/A',
      sortable: true,
    },
    {
      name: 'Discount',
      selector: (row) => row.discount_amount || 'N/A',
      sortable: true,
    },
    {
      name: 'Warranty Type',
      selector: (row) =>
        row.warranty_type !== 'null' ? row.warranty_type : 'N/A',
      sortable: true,
    },
    {
      name: 'Warranty Period',
      selector: (row) =>
        row.warranty_period !== 'undefined' ? row.warranty_period : 'N/A',
      sortable: true,
    },
    {
      name: 'Action',
      minWidth: '220px',
      cell: (row) => (
        <div>
          <GrayButton
            className='circle-btn mr-1'
            onClick={() =>
              handleBarCodeGeneration(
                row.product_code ? row.product_code : row.codes
              )
            }
          >
            <img src={Images.BarCode} alt='...' />
          </GrayButton>

          <Link to={`/dashboard/inventory/product/show/${row.uid}`}>
            <SuccessButton className='circle-btn mr-1'>
              <Eye size={16} />
            </SuccessButton>
          </Link>

          <Link to={`/dashboard/inventory/product/edit/${row.uid}`}>
            <SuccessButton className='circle-btn mr-1'>
              <Edit2 size={16} />
            </SuccessButton>
          </Link>

          <DangerButton
            className='circle-btn'
            onClick={() =>
              setDelete({ value: row, show: true, loading: false })
            }
          >
            <Trash2 size={16} />
          </DangerButton>
        </div>
      ),
    },
  ];

  // handle bar code generation
  const handleBarCodeGeneration = (data) => {
    setSeperateCodeModal({ value: data, show: true, loading: false });
    console.log(data);
  };

  return (
    <div>
      <Layout
        page={t('inventory / product list')}
        message={t('Products that you usually sell & buy.')}
        container='container-fluid'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/inventory/product/new'>
              <GrayButton type='button'>
                <Plus size={15} style={{ marginRight: 5 }} />
                <span style={{ fontSize: 13 }}>{t('ADD PRODUCT')}</span>
              </GrayButton>
            </Link>
            <GrayButton
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDF}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      />

      <Main>
        <Container.Column className='pr-4 mt-2 mb-2'>
          <div className='d-sm-flex justify-content-end pr-2'>
            {/* Category options */}
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 200 }}
            >
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>
                  {t('Select Category')}
                </Text>
                <SingleSelect
                  borderRadius={30}
                  placeholder='customer'
                  options={categoryData}
                  value={(event) => setCategory(event.value)}
                />
              </FormGroup>
            </div>

            {/* Brand options */}
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 200 }}
            >
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>
                  {t('Select Brand')}
                </Text>
                <SingleSelect
                  borderRadius={30}
                  placeholder='status'
                  options={brandData}
                  value={(event) => setBrand(event.value)}
                />
              </FormGroup>
            </div>

            {/* Supplier options */}
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 200 }}
            >
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>
                  {t('Select Supplier')}
                </Text>
                <SingleSelect
                  borderRadius={30}
                  placeholder='status'
                  options={supplierData}
                  value={(event) => setSupplier(event.value)}
                />
              </FormGroup>
            </div>

            {/* From Datepicker */}
            <div
              className='pr-sm-2 mb-2 mb-sm-0'
              style={{ width: size.width <= 576 ? '100%' : 160 }}
            >
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>{t('From')}</Text>
                <DatePicker
                  className='rounded-pill'
                  selected={(data) => setDate(data)}
                  deafultValue={date}
                />
              </FormGroup>
            </div>

            {/* To Datepicker */}
            <div style={{ width: size.width <= 576 ? '100%' : 160 }}>
              <FormGroup className='mb-0'>
                <Text className='text-capitalize fs-13 mb-1'>{t('To')}</Text>
                <DatePicker
                  className='rounded-pill'
                  selected={(data) => setToDate(data)}
                  deafultValue={toDate}
                />
              </FormGroup>
            </div>
          </div>
        </Container.Column>
        {serverError ? <NetworkError message={t('Network error!')} /> : null}

        {!serverError ? (
          <Container.Column>
            <DataTable
              columns={columns}
              data={data}
              loading={loading}
              totalRows={totalRows}
              customStyles={customStyles}
              handlePerRowsChange={handlePerRowsChange}
              handlePageChange={handlePageChange}
              noDataMessage='No product available'
              searchable
              placeholder={'Search product'}
              search={handleSearch}
              suggestion={handleSuggestion}
              searchLoading={searchLoading}
              clearSearch={() => fetchData(1)}
            />
          </Container.Column>
        ) : null}
      </Main>

      {/* Delete confirmation modal */}
      <DeleteModal
        show={isDelete.show}
        loading={isDelete.loading}
        message={
          <div>
            <Text className='mb-0 fs-15'>Want to delete?</Text>
            {isDelete.value && isDelete.value.featured_image ? (
              <img
                src={isDelete.value.featured_image}
                className='img-fluid'
                alt='...'
              />
            ) : null}
          </div>
        }
        onHide={() => setDelete({ value: null, show: false, loading: false })}
        doDelete={handleDelete}
      />

      {/* bar code modal */}
      <PrimaryModal
        show={seperatecodemodal.show}
        loading={seperatecodemodal.loading}
        onHide={() =>
          setSeperateCodeModal({ value: null, show: false, loading: false })
        }
      >
        <div className='text-center'>
          <Text>Product Codes</Text>
          {seperatecodemodal &&
          seperatecodemodal.value &&
          typeof seperatecodemodal.value === 'object' ? (
            seperatecodemodal.value.map((item, index) => {
              return (
                <div key={index}>
                  <Barcode value={item.code} />
                </div>
              );
            })
          ) : (
            <Barcode value={seperatecodemodal.value} />
          )}
        </div>
      </PrimaryModal>
    </div>
  );
};

export default Index;
