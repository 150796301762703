import Brand from "./Brand";
import Category from "./Category";
import Product from "./Product";
import SubCategory from "./SubCategory";
import Supplier from "./Supplier";
import Stock from "./Stock";


const Inventory = {
    Brand,
    Category,
    Product,
    SubCategory,
    Supplier,
    Stock
}

export default Inventory;