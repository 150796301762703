import { useState, useEffect, useCallback } from 'react';
import { ArrowLeft } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import { Toastify } from '../../components/toastify/Toastify';
import { Container } from '../../components/container/Index';
import { Layout } from '../../components/layout/Index';
import { Main } from '../../components/layout/Index';
import {
  GrayButton,
  PrimaryButton,
  SuccessButton,
} from '../../components/button/Index';

import { Requests } from '../../utils/Http/Index';
import { FormGroup } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Text } from '../../components/text/Text';

const EditWithdraw = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  // const [error, setError] = useState(false)
  const [isMounted, setIsMounted] = useState(true); // New state
  const {
    register,
    handleSubmit,
    setValue,
    clearErrors,
    setError,
    formState: { errors },
  } = useForm();


    useEffect(() => {
    setIsMounted(true); // Set to true when the component mounts

    return () => {
      setIsMounted(false); // Set to false when the component unmounts
    };
  }, []);



  // Submit Form
  const onSubmit = async (data) => {
    console.log(data)
    const dokan_uid = localStorage.getItem('dokanuid');

    let formData = new FormData();
    formData.append('dokan_uid', dokan_uid);
    formData.append('title', data.title);
    formData.append('date', data.date);
    formData.append('amount', data.amount);
    formData.append('description', data.description);

  try {
      const response = await Requests.AccountingAll.Account.AddWithdraw(formData);

      if (isMounted) {
        // Check if the component is still mounted before updating state
        if (response.status === 201 || response.status === 200) {
          Toastify.Success(t('Withdraw Updated Successfully'));
          history.push('/dashboard/accounting/account-list');
        } else {
          Toastify.Error(t('Something went wrong'));
        }
      }
    } catch (error) {
      if (isMounted) {
        // Check if the component is still mounted before updating state
        console.error('Error fetching accounts with query:', error);
        setError(true);
      }
    } finally {
      if (isMounted) {
        // Check if the component is still mounted before updating state
        setLoading(false);
      }
    }
  
  };

  return (
    <>
      <Layout
        page={t('dashboard / Accounting / Edit Withdraw')}
        message={t('Update Withdraw amount in Accounting.')}
        container='container-fluid'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/accounting/account-list'>
              <GrayButton type='button'>
                <ArrowLeft size={15} />
                <span style={{ fontSize: 13 }}>BACK</span>
              </GrayButton>
            </Link>
          </div>
        }
      />

      {/* <Container.Row> */}
      {/* Name */}
      <form onSubmit={handleSubmit(onSubmit)} className='mx-3'>
        <Container.Row>
          <Container.Column className='col-lg-6'>
            <FormGroup>
              {errors.title && errors.title.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.title && errors.title.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Name / Withdraw Purpose')}
                  <span className='text-danger'> *</span>
                </Text>
              )}

              <input
                type='text'
                className={
                  errors.title
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                placeholder={t('Enter Name / Withdraw Purpose')}
                {...register('name', {
                  required: t('Name / Withdraw Purpose is required'),
                })}
              />
            </FormGroup>
          </Container.Column>

          <Container.Column className='col-lg-6'>
            <FormGroup>
              {errors.amount && errors.amount.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.amount && errors.amount.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Amount')}
                  <span className='text-danger'> *</span>
                </Text>
              )}

              <input
                type='number'
                className={
                  errors.amount
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                placeholder={t('Enter Amount')}
                {...register('amount', {
                  required: t('Amount is required'),
                })}
              />
            </FormGroup>
          </Container.Column>

          {/* Phone */}
          <Container.Column className='col-lg-6'>
            <FormGroup>
              {errors.date && errors.date.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.date && errors.date.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Date')} <span className='text-danger'> *</span>
                </Text>
              )}

              <input
                type='date'
                className={
                  errors.date
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                {...register('date', {
                  required: t('Date is required'),
                })}
              />
            </FormGroup>
          </Container.Column>
          <Container.Column className='col-lg-12'>
            <FormGroup>
              {errors.description && errors.description.message ? (
                <Text className='text-danger fs-13 mb-1'>
                  {errors.description && errors.description.message}
                </Text>
              ) : (
                <Text className='text-capitalized fs-13 mb-1'>
                  {t('Short Description')}
                  {/* <span className="text-danger"> *</span> */}
                </Text>
              )}

              <textarea
                type='text'
                className={
                  errors.description
                    ? 'form-control shadow-none error'
                    : 'form-control shadow-none'
                }
                placeholder='Write Description Here...'
                {...register('description')}
              />
            </FormGroup>
          </Container.Column>

          {/* </Container.Row> */}
        </Container.Row>
        <div className=' mx-3 d-flex justify-content-end align-items-end'>
          <PrimaryButton className='px-4' disabled={loading}>
            {/* {loading
                ? creating
                  ? t('Creating ...')
                  : t('Updating ...')
                : creating
                ? t('Update')
                : t('Create')} */}
            Create
          </PrimaryButton>
        </div>
      </form>
    </>
  );
};

export default EditWithdraw;
