import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { FormGroup } from '../../components/formGroup/FormGroup';
import { NetworkError } from '../../components/501/NetworkError';
import { DatePicker } from '../../components/datePicker/Index';
import { Layout, Main } from '../../components/layout/Index';
import { Container } from '../../components/container/Index';
import { SingleSelect } from '../../components/select/Index';
import { NoContent } from '../../components/204/NoContent';
import { DataTable } from '../../components/table/Index';
import { Loader } from '../../components/loading/Index';
import { Card } from '../../components/card/Index';
import { Text } from '../../components/text/Text';
import { Requests } from '../../utils/Http/Index';
import { DangerButton, SuccessButton } from '../../components/button/Index';
import { Edit2, Eye, Trash2 } from 'react-feather';
import { useWindowSize } from '../../components/window/windowSize';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import moment from 'moment';
import axiosInstance from '../../utils/Http/axiosInterceptor';
import { dateFormate } from '../../utils/_heplers';

const AccountList = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [fromdate, setFromdate] = useState(new Date());
  const [category, setCategory] = useState(null);
  const [brand, setBrand] = useState(null);
  const [singleCustomer, setSingleCustomer] = useState({});
  const [error, setError] = useState(false);
  const size = useWindowSize();
  const [date, setDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [searchLoading, setsearchLoading] = useState(false);

  const fetchUsers = useCallback(async (perPage) => {
    setLoading(true);
    const response = await Requests.AccountingAll.Account.AccountList();
    setData(response.data.data);
    setTotalRows(response.data.total);
    setLoading(false);
  }, []);

  const fetchUsersWithQuery = useCallback(
    async (date, toDate, category) => {
      setLoading(true);
      const formattedDateFrom = date ? moment(date).format('YYYY-MM-DD') : '';
      const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
      try {
        const response = await Requests.AccountingAll.Account.AccountList(
          perPage,
          formattedDateFrom,
          formattedDateTo,
          category
        );
        setData(response.data.data);
        setTotalRows(response.data.total);
      } catch (error) {
        // Handle error
        console.error('Error fetching accounts with query:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    },
    [date, toDate, perPage, category]
  );

  useEffect(() => {
    if (date && toDate) {
      // If date and toDate are set, fetch with query parameters
      fetchUsersWithQuery(date, toDate, category);
    } else if (category) {
      // If date and toDate are set, fetch with query parameters
      fetchUsersWithQuery(date, toDate, category);
    } else {
      // Otherwise, fetch without query parameters
      fetchUsers();
    }
  }, [fetchUsers, fetchUsersWithQuery, category]);

  const statusOptions = [
    { label: 'Add Money', value: 'add_money' },
    { label: 'Add Withdraw', value: 'add_withdraw' },
    { label: 'Add Expense', value: 'add_expense' },
  ];

  const handlePageChange = (page) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    const response = await axiosInstance.get(
      `https://reqres.in/api/users?page=${page}&per_page=${newPerPage}&delay=1`
    );

    setData(response.data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // Handle search
  const handleSearch = async (data) => {
    try {
      setsearchLoading(true);
      const response = await Requests.AccountingAll.Account.AccountingSearch(
        data
      );
      if (response && response.status === 200) setData(response.data.data);
      setsearchLoading(false);
    } catch (error) {
      if (error) {
        setsearchLoading(false);
      }
    }
  };

  // Handle search suggestion
  const handleSuggestion = async (value) => {
    let data = {
      results: [],
      message: null,
    };
    const response = await Requests.AccountingAll.Account.AccountingSearch(
      value
    );
    console.log(response);

    if (response && response.data.data && response.data.data.length) {
      for (let i = 0; i < response.data.data.length; i++) {
        const element = response.data.data[i];
        data.results.push(element.title);
      }
    } else {
      data.message = 'No results found';
    }

    return data;
  };

  const columns = [
    {
      name: 'Name',
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: 'Category',
      selector: (row) => {
        if (row?.trans_type === 'add_money') {
          return 'Add Money';
        } else if (row?.trans_type === 'add_expense') {
          return 'Add Expense';
        } else if (row?.trans_type === 'add_withdraw') {
          return 'Withdraw Money';
        }
      },
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => dateFormate(row.date),
      sortable: true,
    },

    {
      name: 'Amount',
      selector: (row) => row.amount,
      sortable: true,
    },

    {
      name: `${t('Action')}`,
      grow: 0,
      minWidth: '220px',
      cell: (row) => {
        if (row.trans_type.includes('add_money')) {
          return (
            <div>
              <Link to={`/dashboard/accounting/edit-money/${row.uid}`}>
                <SuccessButton
                  type='button'
                  className='circle-btn mr-1'
                  // onClick={() => handleAction(row)}
                >
                  <Edit2 size={16} />
                </SuccessButton>
              </Link>
            </div>
          );
        } else if (row.trans_type.includes('add_expense')) {
          return (
            <div>
              <Link to={`/dashboard/accounting/edit-expense/${row.uid}`}>
                <SuccessButton
                  type='button'
                  className='circle-btn mr-1'
                  // onClick={() => handleAction(row)}
                >
                  <Edit2 size={16} />
                </SuccessButton>
              </Link>
            </div>
          );
        } else if (row.trans_type.includes('add_withdraw')) {
          return (
            <div>
              <Link to={`/dashboard/accounting/edit-withdraw/${row.uid}`}>
                <SuccessButton
                  type='button'
                  className='circle-btn mr-1'
                  // onClick={() => handleAction(row)}
                >
                  <Edit2 size={16} />
                </SuccessButton>
              </Link>
            </div>
          );
        }
      },
    },
  ];

  return (
    <div>
      <Layout
        page='DASHBOARD / ACCOUNTING / ACCOUNTING LIST'
        message='Amount, Expense and Withdraw wise Accounting List. '
        container='container-fluid'
        printable
        printData={'No data'}
      >
        <Main>
          {loading && !data.length ? <Loader /> : null}
          <>
            <Container.Column className='pr-4'>
              <div className='d-sm-flex justify-content-end pr-2'>
                {/* Status options */}
                <div
                  className='pr-sm-2 mb-2 mb-sm-0'
                  style={{ width: size.width <= 576 ? '100%' : 200 }}
                >
                  <FormGroup className='mb-0'>
                    <Text className='text-capitalize fs-13 mb-1'>
                      {t('Select Category')}
                    </Text>
                    <SingleSelect
                      borderRadius={30}
                      placeholder='Category'
                      options={statusOptions}
                      // value={event => handleStatusFilter(event.value)}
                      value={(data) => {
                        setCategory(data.value);
                      }}
                    />
                  </FormGroup>
                </div>

                {/* From Datepicker */}
                <div
                  className='pr-sm-2 mb-2 mb-sm-0'
                  style={{ width: size.width <= 576 ? '100%' : 160 }}
                >
                  <FormGroup className='mb-0'>
                    <Text className='text-capitalize fs-13 mb-1'>
                      {t('From')}
                    </Text>
                    <DatePicker
                      className='rounded-pill'
                      selected={(data) => setDate(data)}
                      deafultValue={date}
                    />
                  </FormGroup>
                </div>

                {/* To Datepicker */}
                <div style={{ width: size.width <= 576 ? '100%' : 160 }}>
                  <FormGroup className='mb-0'>
                    <Text className='text-capitalize fs-13 mb-1'>
                      {t('To')}
                    </Text>
                    <DatePicker
                      className='rounded-pill'
                      selected={(data) => setToDate(data)}
                      deafultValue={toDate}
                    />
                  </FormGroup>
                </div>
              </div>
            </Container.Column>
          </>

          {!loading && error && !data.length ? (
            <NetworkError message='Network Error.' />
          ) : !loading && !data.length ? (
            <NoContent message='No Content.' />
          ) : (
            <>
              <Container.Column className='pl-0'>
                <DataTable
                  columns={columns}
                  data={data}
                  loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  searchable
                  noDataMessage={t('No Accounting Available')}
                  placeholder={'Search By Name'}
                  search={handleSearch}
                  suggestion={handleSuggestion}
                  searchLoading={searchLoading}
                  clearSearch={() => fetchUsers()}
                />
              </Container.Column>
            </>
          )}
        </Main>
      </Layout>
    </div>
  );
};

export default AccountList;
