import React from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, MainDashboard } from '../../components/layout/Index';

import { Image } from '../../components/image/Index';
import { Images } from '../../utils/Images';

const Index = ({ match }) => {
  const { t } = useTranslation();

  return (
    <div>
      <Layout
        page={t('dokan_dashboard')}
        message={t('Welcome to dashboard')}
        container='container-fluid'
      />

      <MainDashboard>
        <div className='d-flex align-items-center justify-content-center mx-auto'>
          <Image src={Images.ComingSoon} />
        </div>
        {/* card items */}
        {/* <Container.Column className='col-sm-6 col-xl-3'>
          <div className='sms-item-card-dashboard  py-4'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='60'
              height='60'
              fill='currentColor'
              class='bi bi-currency-exchange'
              viewBox='0 0 16 16'
            >
              <path d='M0 5a5 5 0 0 0 4.027 4.905 6.5 6.5 0 0 1 .544-2.073C3.695 7.536 3.132 6.864 3 5.91h-.5v-.426h.466V5.05q-.001-.07.004-.135H2.5v-.427h.511C3.236 3.24 4.213 2.5 5.681 2.5c.316 0 .59.031.819.085v.733a3.5 3.5 0 0 0-.815-.082c-.919 0-1.538.466-1.734 1.252h1.917v.427h-1.98q-.004.07-.003.147v.422h1.983v.427H3.93c.118.602.468 1.03 1.005 1.229a6.5 6.5 0 0 1 4.97-3.113A5.002 5.002 0 0 0 0 5m16 5.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0m-7.75 1.322c.069.835.746 1.485 1.964 1.562V14h.54v-.62c1.259-.086 1.996-.74 1.996-1.69 0-.865-.563-1.31-1.57-1.54l-.426-.1V8.374c.54.06.884.347.966.745h.948c-.07-.804-.779-1.433-1.914-1.502V7h-.54v.629c-1.076.103-1.808.732-1.808 1.622 0 .787.544 1.288 1.45 1.493l.358.085v1.78c-.554-.08-.92-.376-1.003-.787zm1.96-1.895c-.532-.12-.82-.364-.82-.732 0-.41.311-.719.824-.809v1.54h-.005zm.622 1.044c.645.145.943.38.943.796 0 .474-.37.8-1.02.86v-1.674z' />
            </svg>
            <div className=''>
              <Text className='fs-30 font-weight-bolder mb-0'>714000 TK.</Text>
              <Text className='fs-14 mb-0'>Total Cash</Text>
            </div>
          </div>
        </Container.Column> */}

        {/* card items */}

        {/* <Container.Column className='col-sm-6 col-xl-3'>
          <div className='sms-item-card-dashboard-two  py-4 '>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='60'
              height='60'
              fill='currentColor'
              class='bi bi-people-fill'
              viewBox='0 0 16 16'
            >
              <path d='M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6m-5.784 6A2.24 2.24 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.3 6.3 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1zM4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5' />
            </svg>
            <div className=''>
              <Text className='fs-30 font-weight-bolder mb-0'>714000</Text>
              <Text className='fs-14 mb-0'>Total Customer</Text>
            </div>
          </div>
        </Container.Column> */}

        {/* card items */}

        {/* <Container.Column className='col-sm-6 col-xl-3'>
          <div className='sms-item-card-dashboard-three   py-4'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='60'
              height='60'
              fill='currentColor'
              class='bi bi-file-earmark-text'
              viewBox='0 0 16 16'
            >
              <path d='M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5m0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5' />
              <path d='M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1z' />
            </svg>
            <div className=''>
              <Text className='fs-30 font-weight-bolder mb-0'>714000</Text>
              <Text className='fs-14 mb-0'>Total Due</Text>
            </div>
          </div>
        </Container.Column> */}

        {/* card items */}

        {/* <Container.Column className='col-sm-6 col-xl-3'>
          <div className='sms-item-card-dashboard-four  py-4'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='60'
              height='60'
              fill='currentColor'
              class='bi bi-box-fill'
              viewBox='0 0 16 16'
            >
              <path
                fill-rule='evenodd'
                d='M15.528 2.973a.75.75 0 0 1 .472.696v8.662a.75.75 0 0 1-.472.696l-7.25 2.9a.75.75 0 0 1-.557 0l-7.25-2.9A.75.75 0 0 1 0 12.331V3.669a.75.75 0 0 1 .471-.696L7.443.184l.004-.001.274-.11a.75.75 0 0 1 .558 0l.274.11.004.001zm-1.374.527L8 5.962 1.846 3.5 1 3.839v.4l6.5 2.6v7.922l.5.2.5-.2V6.84l6.5-2.6v-.4l-.846-.339Z'
              />
            </svg>
            <div className=''>
              <Text className='fs-30 font-weight-bolder mb-0'>71400</Text>
              <Text className='fs-14 mb-0'>Total Products</Text>
            </div>
          </div>
        </Container.Column> */}
      </MainDashboard>
      {/* <LayoutDashboad
        message={t('Inventory & POS')}
        container='container-fluid'
      /> */}

      {/* <PosCards />
      <PosTable /> */}
      {/* <LayoutDashboad
        message={t('Servicing')}
        container='container-fluid'
      /> */}
      {/* <ServiceCards />
      <ServiceTable /> */}
    </div>
  );
};

export default Index;
