import Cash from "./Cash";
import DailyDue from "./DailyDue";
import DailyExpense from "./DailyExpense";
import DailyPayable from "./DailyPayable"
import DailySell from "./DailySell";
import Ledger from "./Ledger"
import Account from "./account"

const AccountingAll= {
    Cash,
    DailyDue,
    DailyExpense,
    DailyPayable,
    DailySell,
    Ledger,
    Account
}

export default AccountingAll