import { API } from '../Api';
import axiosInstance from './axiosInterceptor';

// Account Registration
const Registration = async (data) => {
  const response = await axiosInstance.post(`${API}/register`, data);
  return response;
};

// Account verify
const VerifyPhone = async (data) => {
  const response = await axiosInstance.post(`${API}/phone/verify`, data);
  return response;
};

// resend verification code
const ResendPhoneVerificationCode = async (data) => {
  const response = await axiosInstance.post(
    `${API}/phone-verification/resend`,
    data
  );
  return response;
};

// Login to account
const Login = async (data) => {
  const response = await axiosInstance.post(`${API}/login`, data);
  return response;
};

const UserProfile = async () => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    const res = await axiosInstance.get(`${API}/profile`, config);
    return res;
  } catch (err) {
    return err.response;
  }
};

const UpdatePassword = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.put(`${API}/change-password`, data, config);
};

const RefreshToken = async () => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  try {
    return await axiosInstance.post(`${API}/refresh`, config);
  } catch (err) {
    return err.response;
  }
};

// for sending reset token or code
const SendEmailOrPhoneForReset = async (data) => {
  const response = await axiosInstance.post(`${API}/password/forgot`, data);
  return response;
};

// for sending reset token or code
const CheckResetPasswordOtp = async (data) => {
  const response = await axiosInstance.post(`${API}/reset/otp-check`, data);
  return response;
};

// for sending reset token or code
const ResetPassword = async (data) => {
  const response = await axiosInstance.post(`${API}/password/reset`, data);
  return response;
};

// email verification
const EmailVerification = async (data) => {
  const response = await axiosInstance.post(
    `${API}/email-verification/send`,
    data
  );
  return response;
};

// verify email
const VerifyEmail = async (data) => {
  return await axiosInstance.post(`${API}/email/verify`, data);
};

// Security Login
const SecurityLogin = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.put(`${API}/security-login`, data, config);
};

// Pass two factor auth
const PassTwoFactor = async (data) => {
  return await axiosInstance.post(`${API}/pass-two-factor-auth`, data);
};

const Auth = {
  Registration,
  Login,
  UserProfile,
  UpdatePassword,
  SendEmailOrPhoneForReset,
  RefreshToken,
  VerifyPhone,
  ResendPhoneVerificationCode,
  CheckResetPasswordOtp,
  ResetPassword,
  EmailVerification,
  VerifyEmail,
  SecurityLogin,
  PassTwoFactor,
};

export default Auth;
