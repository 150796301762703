import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Requests } from "../../utils/Http/Index";
import { PrimaryButton } from "../button/Index";
import { Container } from "../container/Index";
import { DatePicker } from "../datePicker/Index";
import { FormGroup } from "../formGroup/FormGroup";
import { PrimaryModal } from "../modal/PrimaryModal";
import { SearchableSelect, SingleSelect } from "../select/Index";
import { Text } from "../text/Text";
import { Toastify } from "../toastify/Toastify";
import { BrandForm } from "./BrandForm";
import { CustomerForm } from "./CustomerForm";
import { Mechanic } from "./Mechanic";

export const ServicingOtherProductAdd = (props) => {
  const { t } = useTranslation();
  const {
    control,
    register,
    handleSubmit,
    setError,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm();

  const data = props.editData ? props.editData : {};
  const partsData = props.partsData ? props.partsData : [];

  const [show_brand_modal, setShowBrandModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [customer, setCustomer] = useState(null);
  const [brand, setBrand] = useState(null);
  
  const [mechanic, setMechanic] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(
    data && data.deliveryDate ? data.deliveryDate : new Date()
  );
  const [totalCost, setTotalCost] = useState(null);
  const [show, setShow] = useState({
    customer: false,
    mechanic: false,
    loading: false,
  });

  // fee
  const [serviceFee, setServiceFee] = useState("");
  const [partsFee, setPartsFee] = useState("");
  const [advancePayment, setAdvancePayment] = useState("");


  const [totalBill, setTotalBill] = useState("");
  const [totalDue, setTotalDue] = useState("");



  useEffect(() => {
    if (props.data && props.data.customer) setCustomer(props.data.customer.uid);
    if (props.data && props.data.mechanic) setMechanic(props.data.mechanic.uid);
    if (props.data && props.data.brand) setBrand(props.data.brand.uid);
  }, [props]);


  const billCalculation = ()=>{
    let total_bill = Number(serviceFee) + Number(partsFee);
    let due = Number(total_bill) - Number(advancePayment);

    if(due < 0){
        due = 0;
    }
    setTotalBill(total_bill);
    setTotalDue(due);
  }
  useEffect(()=>{
    billCalculation();
  }, [serviceFee, partsFee, advancePayment])

  // Handle customer creation
  const handleCustomerCreation = async (data) => {
    try {
      setShow({ ...show, loading: true });
      const response = await Requests.Customer.AddCustomer(data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }
      setShow({ ...show, customer: false, loading: false });
    } catch (error) {
      if (error) {
        setShow({ ...show, loading: false });
        Toastify.Success("Network Error!!!");
      }
    }
  };

  // Handle customer search
  const handleCustomerSearch = async (data) => {
    try {
      const results = [];
      const response = await Requests.Customer.CustomerSearch(data);
      if (response && response.status === 200) {
        if (response.data && response.data.data && response.data.data.length) {
          for (let i = 0; i < response.data.data.length; i++) {
            const element = response.data.data[i];
            results.push({
              label: element.name,
              value: element.uid,
              name: element.name,
              image: element.image,
            });
          }
        }
        return results;
      }
    } catch (error) {
      if (error) return [];
    }
  };

  // Handle mechanic creation
  const handleMechanicCreation = async (data) => {
    try {
      setShow({ ...show, loading: true });
      const response = await Requests.Mechanic.Store(data);
      if (response && response.status === 201) {
        Toastify.Success(response.data.message);
      }
      setShow({ ...show, mechanic: false, loading: false });
    } catch (error) {
      if (error) {
        setShow({ ...show, loading: false });
        Toastify.Success("Network Error!!!");
      }
    }
  };

  // Handle mechanic search
  const handleMechanicSearch = async (data) => {
    try {
      const results = [];
      const response = await Requests.Mechanic.Search(data);
      if (response && response.status === 200) {
        if (response.data && response.data.data && response.data.data.length) {
          for (let i = 0; i < response.data.data.length; i++) {
            const element = response.data.data[i];
            results.push({
              label: element.name,
              value: element.uid,
              name: element.name,
              image: element.image,
            });
          }
        }
        return results;
      }
    } catch (error) {
      if (error) return [];
    }
  };

  // handle brand create
  const handleBrandCreate = async (data) => {
    setLoading(true);
    try {
      const response = await Requests.Inventory.Brand.DokanBrandStore(data);
      if (response.status === 201) {
        props.fetchBrands();
        Toastify.Success("Brand Created Successfully");
      }
      setLoading(false);
      setShowBrandModal(false);
    } catch (error) {
      if (error.response && error.response.status === 422) {
        Toastify.Error("Brand Can't Be Created");
      } else {
        Toastify.Error("Network Error Occured");
      }
      setLoading(false);
      setShowBrandModal(false);
    }
  };

  // Submit Form
  const onSubmit = async (data) => {

    setLoading(true);

    if (!customer) {
      setError("customer_uid", {
        type: "manual",
        message: "Select customer",
      });
    }

    if (!brand) {
      setError("brand_uid", {
        type: "manual",
        message: "Select Brand",
      });
    }

    if (!mechanic) {
      setError("mechanic_uid", {
        type: "manual",
        message: "Select mechanic",
      });
    }

    if (!deliveryDate) {
      setError("delivery_date", {
        type: "manual",
        message: "Delivery date is required",
      });
    }

    
    const formData = {
      ...data,
      brand_uid: brand,
      customer_uid: customer,
      mechanic_uid: mechanic,
      dokan_uid: localStorage.getItem("dokanuid"),
      delivery_date: deliveryDate,

      service_fee: serviceFee,
      parts_fee: partsFee,
      advance_payment: advancePayment,
      total_bill : totalBill,
      total_due : totalDue,
    };
    

    if (customer && mechanic && brand) {
      console.log(formData);
      setLoading(false);
      props.onSubmit(formData)
    }

    setLoading(false);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container.Row>
          {/* Customer */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.customer_uid && errors.customer_uid.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.customer_uid && errors.customer_uid.message}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setShow({ ...show, customer: true })}
                  >
                    {" "}
                    / ADD CUSTOMER
                  </span>
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Customer")}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setShow({ ...show, customer: true })}
                  >
                    {" "}
                    / ADD CUSTOMER
                  </span>
                  <span className="text-danger"> *</span>
                </Text>
              )}

              <SearchableSelect
                borderRadius={4}
                placeholder={t("Select customer")}
                defaultValue={
                  data && data.customer
                    ? {
                        label: data.customer.label,
                        value: data.customer.value,
                      }
                    : null
                }
                search={handleCustomerSearch}
                options={props.customers}
                values={(data) => {
                  setCustomer(data.value);
                  clearErrors("customer_uid");
                }}
              />
            </FormGroup>
          </Container.Column>

          {/* Product name */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.product_name && errors.product_name.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.product_name && errors.product_name.message}
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Product name")}
                  <span className="text-danger"> *</span>
                </Text>
              )}

              <input
                type="text"
                className={
                  errors.product_name
                    ? "form-control shadow-none error"
                    : "form-control shadow-none"
                }
                placeholder={t("Enter product name")}
                defaultValue={data ? data.product_name : null}
                {...register("product_name", {
                  required: t("Product name is required"),
                })}
              />
            </FormGroup>
          </Container.Column>

          {/* Product Brand */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.brand_uid && errors.brand_uid.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.brand_uid && errors.brand_uid.message}
                  <span
                    className="text-primary cursor-pointer"
                    onClick={() => setShowBrandModal(true)}
                  >
                    {" "}
                    / ADD Brand
                  </span>
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Brand")}
                  <span
                    className="brand-add"
                    onClick={() => setShowBrandModal(true)}
                  >
                    {" "}
                    / ADD Brand
                  </span>
                  <span className="text-danger"> *</span>
                </Text>
              )}


              <Controller
                name="brand_uid"
                control={control}
                render={({ field }) => (
                  <SingleSelect
                    error={errors.brand_uid}
                    placeholder="a brand"
                    options={props.brands}
                    value={(data) =>{
                      setBrand(data.value);
                      clearErrors("brand_uid");
                    }
                    }
                  />
                )}
              />
            </FormGroup>
          </Container.Column>

          {/* Mechanic */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.mechanic_uid && errors.mechanic_uid.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.mechanic_uid && errors.mechanic_uid.message}
                  {/* <span className="text-primary cursor-pointer"
                                        onClick={() => setShow({ ...show, mechanic: true })}
                                    > / ADD MECHANIC</span> */}
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Mechanic")}
                  {/* <span className="text-primary cursor-pointer"
                                        onClick={() => setShow({ ...show, mechanic: true })}
                                    > / ADD MECHANIC</span> */}
                  <span className="text-danger"> *</span>
                </Text>
              )}

              <SearchableSelect
                borderRadius={4}
                placeholder={t("Select mechanic")}
                defaultValue={
                  props.data && props.data.mechanic
                    ? {
                        label: props.data.mechanic.name,
                        value: props.data.mechanic.uid,
                      }
                    : null
                }
                search={handleMechanicSearch}
                values={(data) => {
                  setMechanic(data.value);
                  clearErrors("mechanic_uid");
                }}
              />
            </FormGroup>
          </Container.Column>

          {/* Service Fee */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.service_fee && errors.service_fee.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.service_fee && errors.service_fee.message}
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Servicing Fee")} <span className="text-danger"> *</span>
                </Text>
              )}

              <input
                type="number"
                className={
                  errors.service_fee
                    ? "form-control shadow-none error"
                    : "form-control shadow-none"
                }
                placeholder="Servicing fee"
                min={0}
                defaultValue={data ? data.service_fee : null}
                {...register("service_fee", {
                  required: t("Servicing fee is required"),
                })}

                onChange={(e)=>{
                  setValue('service_fee', e.target.value);
                  clearErrors('service_fee');
                  setServiceFee(e.target.value);
                }}
              />
            </FormGroup>
          </Container.Column>

          {/* Parts Fee */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.parts_fee && errors.parts_fee.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.parts_fee && errors.parts_fee.message}
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Parts Fee")}
                </Text>
              )}

              <input
                type="number"
                className={
                  errors.parts_fee
                    ? "form-control shadow-none error"
                    : "form-control shadow-none"
                }
                placeholder="Parts fee"
                min={0}
                defaultValue={data ? data.parts_fee : null}
                {...register("parts_fee")}

                onChange={(e)=>{
                  setValue('parts_fee', e.target.value);
                  clearErrors('parts_fee');
                  setPartsFee(e.target.value);
                }}
              />
            </FormGroup>
          </Container.Column>

          {/* Advance payment */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.advance && errors.advance_payment.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.advance_payment && errors.advance_payment.message}
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Advance Payment")}
                </Text>
              )}

              <input
                type="number"
                className={
                  errors.advance_payment
                    ? "form-control shadow-none error"
                    : "form-control shadow-none"
                }
                placeholder="Advance Payment"
                min={0}
                defaultValue={data ? data.advance_payment : null}
                {...register("advance_payment")}

                onChange={(e)=>{
                  setValue('advance_payment', e.target.value);
                  clearErrors('advance_payment');
                  setAdvancePayment(e.target.value);
                }}
              />
            </FormGroup>
          </Container.Column>

          {/* Delivery date */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.delivery_date && errors.delivery_date.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.delivery_date && errors.delivery_date.message}
                </Text>
              ) : (
                <Text className="text-capitalize fs-13 mb-1">
                  {t("Delivery date")}
                  <span className="text-danger"> *</span>
                </Text>
              )}

              <DatePicker
                selected={(data) => setDeliveryDate(data)}
                deafultValue={deliveryDate}
              />
            </FormGroup>
          </Container.Column>

          {/* long description */}
          <Container.Column className="col-lg-6">
            <FormGroup>
              {errors.note && errors.note.message ? (
                <Text className="text-danger fs-13 mb-1">
                  {errors.note && errors.note.message}
                </Text>
              ) : (
                <Text className="fs-13 mb-0">{t("Note")}</Text>
              )}
              <textarea
                rows="3"
                className={
                  errors.note
                    ? "form-control shadow-none error"
                    : "form-control shadow-none"
                }
                placeholder={t("Note")}
                {...register("note")}
              />
            </FormGroup>
          </Container.Column>

        

          {/* Bill Info */}
          <Container.Column className="col-lg-12">
            <div>
                <h3>Total Bill: <span className="text-danger"> {totalBill}</span></h3>
                <h5>Due Bill: <span className="text-danger"> {totalDue}</span></h5>
            </div>
          </Container.Column>

          {/* Submit button */}
          <Container.Column className="text-right">
            <PrimaryButton className="px-4" disabled={props.isLoading}>
              {props.isLoading ? t("LOADING...") : t("SUBMIT")}
            </PrimaryButton>
          </Container.Column>
        </Container.Row>
      </form>

      {/* Customer create modal */}
      <PrimaryModal
        title={t("Create Customer")}
        show={show.customer}
        size="lg"
        onHide={() => setShow({ ...show, customer: false, loading: false })}
      >
        <CustomerForm
          create={true}
          loading={show.loading}
          submit={(data) => handleCustomerCreation(data)}
        />
      </PrimaryModal>

      {/* Mechanic create modal */}
      <PrimaryModal
        title={t("Create Mechanic")}
        show={show.mechanic}
        size="lg"
        onHide={() => setShow({ ...show, mechanic: false, loading: false })}
      >
        <Mechanic
          create={true}
          isLoading={show.loading}
          onSubmit={(data) => handleMechanicCreation(data)}
        />
      </PrimaryModal>

      {/* Create Brand Modal */}
      <PrimaryModal
        show={show_brand_modal}
        onHide={() => setShowBrandModal(false)}
        title="Create Brand"
        size="md"
      >
        <BrandForm submit={handleBrandCreate} loading={loading} create={true} />
      </PrimaryModal>
    </div>
  );
};
