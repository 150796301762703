import { API } from '../../Api';
import axiosInstance from '../axiosInterceptor';

const DokanBrandList = async (page = null, perpage = null) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  if (page == null && perpage == null) {
    return await axiosInstance.get(
      `${API}/brands?dokan_uid=${localStorage.getItem('dokanuid')}`,
      config
    );
  } else
    return await axiosInstance.get(
      `${API}/brands?dokan_uid=${localStorage.getItem(
        'dokanuid'
      )}&page=${page}&per_page=${perpage}`,
      config
    );
};

const DokanBrandSearch = async (search) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/brands?dokan_uid=${localStorage.getItem('dokanuid')}&q=${search}`,
    config
  );
};

const DokanBrandStore = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/brands`, data, config);
};

const DokanBrandUpdate = async (data, uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/brands/${uid}`, data, config);
};

const DokanBrandDelete = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.delete(
    `${API}/brands/${data}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const Brand = {
  DokanBrandList,
  DokanBrandSearch,
  DokanBrandStore,
  DokanBrandUpdate,
  DokanBrandDelete,
};

export default Brand;
