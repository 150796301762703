import { API } from '../../Api';
import axiosInstance from '../axiosInterceptor';

// for fetching all accounts
const AccountList = async (perpage = 0, from, to, trans_type) => {
  const data = {
    perpage,
    from,
    to,
    trans_type,
  };
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    params: data,
  };
  return await axiosInstance.get(
    `${API}/accounting/account-list?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};

// for adding Money
const AddMoney = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/accounting/add-money`, data, config);
};

// for adding withdraw
const AddWithdraw = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(
    `${API}/accounting/add-withdraw`,
    data,
    config
  );
};

// for adding Money
const AddExpense = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(
    `${API}/accounting/add-expense`,
    data,
    config
  );
};

// accounting/add-money/1703764281515813?dokan_uid={{dokan_uid}}
// for updating add
const UpdateMoney = async (data, id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.put(
    `${API}/accounting/add-money/${id}?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    data,
    config
  );
};

// for updating add
const UpdateWithdraw = async (data, id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.put(
    `${API}/accounting/add-withdraw/${id}?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    data,
    config
  );
};

// for updating add
const UpdateExpense = async (data, id) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.put(
    `${API}/accounting/add-expense/${id}?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    data,
    config
  );
};

// for fetching all accounts
const SingleAccounting = async (id) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/accounting/account-list/${id}?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};

// accounting/account-list/1703766172298041?dokan_uid={{dokan_uid}}

// for searching employee
const AccountingSearch = async (search) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/accounting/account-list?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}`,
    config
  );
};
// {{host}}/accounting/account-list?dokan_uid={{dokan_uid}}&q=hello

const Account = {
  AddExpense,
  AddMoney,
  AccountList,
  AddWithdraw,
  UpdateExpense,
  UpdateMoney,
  UpdateWithdraw,
  SingleAccounting,
  AccountingSearch,
};

export default Account;
