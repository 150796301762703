import React, { useCallback, useEffect, useState } from 'react';
import { ArrowLeft } from 'react-feather';
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import { NoContent } from '../../../components/204/NoContent';
import { NetworkError } from '../../../components/501/NetworkError';
import { GrayButton } from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { SupplierForm } from '../../../components/form/SupplierForm';
import { Layout, Main } from '../../../components/layout/Index';
import { Loader } from '../../../components/loading/Index';
import { Toastify } from '../../../components/toastify/Toastify';
import { Requests } from '../../../utils/Http/Index';
import { SupplierBillForm } from '../../../components/form/SupplierBillForm';

const Index = () => {
  const history = useHistory();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [isUpdate, setUpdate] = useState(true);
  const [error, setError] = useState(false);
  const [suppliers, setSuppliers] = useState([]);
  const [pageError, setPageError] = useState(false);

  const fetchSuppliers = useCallback(async () => {
    try {
      const response = await Requests.Inventory.Supplier.DokanSupplierList();

      const suppliers = response.data.data.map((supplier) => {
        return { label: supplier.name, value: supplier.uid };
      });
      setSuppliers(suppliers);
    } catch (error) {
      if (error) setPageError(true);
    }
  }, []);

  useEffect(() => {
    Promise.all([fetchSuppliers()]);
  }, [fetchSuppliers]);

  const fetchData = useCallback(async () => {
    try {
      const response = await Requests.Inventory.Supplier.DokanSupplierBillShow(
        id
      );
      if (response && response.status === 200) {
        setData(response.data.data);
        setUpdate(false);
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        setError(true);
      }
      setUpdate(false);
    }
  }, [id]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  // Handle submit
  const UpdateSupplier = async (data) => {
    setLoading(true);
    try {
      const newdata = {
        ...data,
        dokan_uid: localStorage.getItem('dokanuid'),
        uid: id,
        _method: 'PUT',
      };
      const res = await Requests.Inventory.Supplier.DokanSupplierBillUpdate(
        newdata
      );
      if (res.status === 200) {
        Toastify.Success('Supplier Updated Successfully');
        history.push('/dashboard/inventory/supplier/list');
      }
      setLoading(false);
    } catch (error) {
      if (error.response && error.response.data && error.response.data.errors) {
        Toastify.Error(Object.values(error.response.data.errors)[0][0]);
      } else {
        Toastify.Error('Some fields are missing.');
      }
      setLoading(false);
    }
  };

  if (isUpdate) return <Loader />;

  return (
    <div>
      <Layout
        page='inventory / edit supplier Bill'
        message='Edit a supplier Bill details of your shop.'
        container='container-fluid'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/inventory/supplier-bill/list'>
              <GrayButton type='button'>
                <ArrowLeft size={15} style={{ marginRight: 5 }} />
                <span style={{ fontSize: 13 }}>BACK</span>
              </GrayButton>
            </Link>
          </div>
        }
      />

      <Main>
        {isUpdate && !error && !data ? <Loader /> : null}
        {!isUpdate && !error && !data ? (
          <NetworkError message='Network Error.' />
        ) : !isUpdate && error && !data ? (
          <NoContent message='No Content.' />
        ) : (
          <Container.Column>
            <SupplierBillForm
              loading={loading}
              submit={UpdateSupplier}
              supplier={data}
              suppliers={suppliers}
              create={false}
            />
          </Container.Column>
        )}
      </Main>
    </div>
  );
};

export default Index;
