import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './style.scss';

import { Menu, Minimize, PlusSquare } from 'react-feather';
import { Image } from '../image/Index';
import Dropdown from './Dropdown';

import { Images } from '../../utils/Images';
// import { NotificationButton } from '../button/Index'
import LanguageSelector from '../../components/languageSelector/Index';
// import { englishToBengali } from '../../utils/_heplers'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Requests } from '../../utils/Http/Index';
import { DangerButton, GrayButton, PrimaryButton } from '../button/Index';
import { Text } from '../text/Text';

const Index = (props) => {
  const [data, setData] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  const fetchData = useCallback(async () => {
    if (history.location.pathname !== '/shop') {
      try {
        const response = await Requests.Settings.DokanSettingInformation();
        if (response.status === 200) setData(response.data);
      } catch (error) {
        if (error) console.log(error);
      }
    }
  }, [history]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const dokantitle = data ? data.dokan.title : 'Dokan';

  // const LogoSrc = data && data.dokan.logo;
  const LogoSrc = data ? data.dokan.logo : Images.DefaultLogo;
  return (
    <div className='navbar-container'>
      <div className='d-flex '>
        <div>
          <Image
            // src={LogoSrc && LogoSrc}
            src={LogoSrc ? LogoSrc : Images.DefaultLogo}
            alt='Company logo'
            x={50}
            y={50}
          />
        </div>
        <div className='d-flex align-items-center ml-auto'>
          {/* POS Button */}
          <Link to='/dashboard/pos/point-of-sell'>
            <PrimaryButton type='button ' className='mr-2 pos-button'>
              <Text className='mb-0 text-white fs-12 d-flex justify-content-center align-items-center'>
                {' '}
                <Minimize size={12} />
                <span className='ml-1'>POS</span>
              </Text>
            </PrimaryButton>
          </Link>

          {/* ADD EXPENSES Button */}
          <Link to='/dashboard/accounting/add-expense'>
            <DangerButton type='button' className='mr-2 add-expenses-button'>
              <Text className='mb-0 fs-12 d-flex justify-content-center align-items-center'>
                <PlusSquare size={12} />{' '}
                <span className='ml-1'>ADD EXPENSES</span>
              </Text>
            </DangerButton>
          </Link>

          {/* Language Selector */}
          <div>
            <LanguageSelector />
          </div>

          {/* Dropdown Menu */}
          <div>
            <Dropdown />
          </div>

          {/* Hamburger Menu for smaller screens */}
          {props.menu && (
            <div className='d-lg-none pl-2'>
              <button
                type='button'
                className='btn shadow-none rounded-circle'
                onClick={props.drawer}
              >
                <Menu size={24} />
              </button>
            </div>
          )}
        </div>

        {/* {props.menu && (
          <div className='d-lg-none pl-2'>
            <button
              type='button'
              className='btn shadow-none rounded-circle'
              onClick={props.drawer}
            >
              <Menu size={24} />
            </button>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default Index;
