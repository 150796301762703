import { API } from '../../Api';
import axiosInstance from '../axiosInterceptor';

// for list of all Daily expense
const DailyExpenseIndex = async (perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/daily-expenses/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}`,
    config
  );
};

// for Daily expense index search
const DailyExpenseSearch = async (query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/daily-expenses/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${query}`,
    config
  );
};

// Daily expense index filter by from date
const FilterByFromDate = async (formDate, perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/daily-expenses/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}&from=${formDate}`,
    config
  );
};

// Daily expense index filter by from and to date
const FilterByFromToDate = async (formDate, toDate, perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/daily-expenses/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}&from=${formDate}&to=${toDate}`,
    config
  );
};

// for daily expense create
const DailyExpenseCreate = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/daily-expenses`, data, config);
};

const DailyExpense = {
  DailyExpenseIndex,
  DailyExpenseSearch,
  FilterByFromDate,
  FilterByFromToDate,
  DailyExpenseCreate,
};

export default DailyExpense;
