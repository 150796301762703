// axiosInterceptor.js
import Axios from 'axios';

// Create Axios instance
const axiosInstance = Axios.create();

// Define login and other authentication routes (paths where dokanuid is not needed)
const authRoutes = ['/register', '/reset', '/shop']; // Exclude '/' and handle it separately if necessary

// Add a request interceptor to check for dokanuid in localStorage
axiosInstance.interceptors.request.use(
  (config) => {
    const isAuthRoute = authRoutes.some((route) =>
      window.location.pathname.startsWith(route)
    );

    // Handle the special case for the root route '/' which is your login page
    const isRootRoute = window.location.pathname === '/';

    // If it's not an auth route or the root route (login page) and dokanuid is missing, redirect to /shop
    if (!isAuthRoute && !isRootRoute && !localStorage.getItem('dokanuid')) {
      // dokanuid is missing, redirect to /shop
      window.location.href = '/shop';
    }

    // Allow the request if it's an auth route or the login page
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle 401 Unauthorized errors
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      // Remove token and dokanuid from localStorage
      localStorage.removeItem('token');
      localStorage.removeItem('dokanuid');

      // Redirect to the login page
      window.location.href = '/'; // Adjust this to match your login page route
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
