import axiosInstance from './axiosInterceptor';
import { API } from '../Api';

const CreatePos = async (data) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.post(
    `${API}/orders?dokan_uid=${localStorage.getItem('dokanuid')}`,
    data,
    config
  );
};

const GenerateInvoice = async () => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return axiosInstance.post(
    `${API}/order-invoice-number/${localStorage.getItem('dokanuid')}`,
    {},
    config
  );
};

// for all customer list
const OrderList = async (
  page = 1,
  perpage,
  fromDate = '',
  toDate = '',
  customer = ''
) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/orders?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&page=${page}&per_page=${perpage}&customer_uid=${customer}&from=${fromDate}&to=${toDate}`,
    config
  );
};

const POS = {
  CreatePos,
  OrderList,
  GenerateInvoice,
};

export default POS;
