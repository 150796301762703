import React, { useCallback, useEffect, useState } from 'react';
import { Edit2, Eye, Plus, Printer } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { NetworkError } from '../../../components/501/NetworkError';
import { GrayButton, SuccessButton } from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { Layout, Main } from '../../../components/layout/Index';
import { DeleteModal } from '../../../components/modal/DeleteModal';
import { DataTable } from '../../../components/table/Index';
import { Toastify } from '../../../components/toastify/Toastify';
import { Requests } from '../../../utils/Http/Index';
import { getCurrentDateTime } from '../../../utils/_heplers';
import BrandImage from '../../../assets/logo.png';

import jsPDF from 'jspdf';
import 'jspdf-autotable';

const Index = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searchLoading, setsearchLoading] = useState(false);
  const [isDelete, setDelete] = useState({ show: false, loading: false });
  const [supplier, setSupplier] = useState(null);
  const [serverError, setServerError] = useState(false);

  const handleGeneratePDF = () => {
    // jsPDF.API.registerFont("RobotoCondensedBold", RobotoCondensedBold);

    const pdf = new jsPDF();
    const columns = ['Name', 'Phone', 'Email', 'Address', 'Note'];

    // Calculate the x-coordinate for the brand image to align it to the right
    const imageX = pdf.internal.pageSize.width - 30;

    // Add brand image on the right
    pdf.addImage(BrandImage, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165; // Adjust the value based on your desired spacing
    const textY = 20;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY, 'SUPPLIER DATA');
    // Add another small text with a different font size

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10; // Set the font size for the small text
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 6, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns], // Headers
      body: data.map((row) => [
        row.name || 'N/A',
        row.phone ?? 'N/A',
        row.email ? row.supplier.name : 'N/A',
        row.address ? row.address : 'N/A',
        row.note ? row.note : 'N/A',
      ]),
      styles: {
        //   fillColor: [104, 126, 255], // RGB color for blue background
        font: 'RobotoCondensedBold',
        textColor: [17, 35, 90], // RGB color for text (adjust as needed)
        halign: 'center', // Horizontal alignment: 'left', 'center', 'right'
        valign: 'middle', // Vertical alignment: 'top', 'middle', 'bottom'
        fontSize: 12, // Font size for the table content
        cellPadding: 2, // Padding inside each cell
        borderBottom: '1px solid black',
        rowStyles: (rowIndex) => {
          // Borders at the top and bottom for each row
          return {
            borderTop: rowIndex === 0 ? '1px solid black' : 'none',
            borderBottom: '1px solid black',
          };
        },
      },
      headStyles: {
        fillColor: [34, 50, 100], // RGB color for header background
        textColor: [255, 255, 255], // RGB color for header text
        fontSize: 10, // Font size for the header
      },
      columnStyles: {
        0: { cellWidth: 40 }, // Set width for the first column
        1: { cellWidth: 'auto' }, // Set auto width for the second column
        // Add more column styles as needed
      },
    });

    pdf.save('supplier_data.pdf');
  };

  const fetchData = useCallback(
    async (page) => {
      try {
        setLoading(true);
        const response =
          await Requests.Inventory.Supplier.DokanSupplierBillList(
            page,
            perPage
          );
        if (response && response.status === 200) {
          setData(response.data.data);
          setTotalRows(response.data.meta.total);
          setServerError(false);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  // handle page change
  const handlePageChange = (page) => fetchData(page);

  // handle row change
  const handlePerRowsChange = async (newPerPage, page) => {
    try {
      setLoading(true);
      const response = await Requests.Inventory.Supplier.DokanSupplierBillList(
        page,
        newPerPage
      );
      setData(response.data.data);
      setPerPage(newPerPage);
      setLoading(false);
    } catch (error) {
      if (error) setLoading(false);
    }
  };

  useEffect(() => {
    fetchData(1);
  }, [fetchData]);

  const columns = [
    {
      name: 'Supplier Name',
      selector: (row) => row?.supplier?.name || 'N/A',
      sortable: true,
    },
    {
      name: 'Phone',
      selector: (row) => row?.supplier?.phone || 'N/A',
      sortable: true,
    },
    {
      name: 'Paid Amount',
      selector: (row) => row.pay_amount || 'N/A',
      sortable: true,
    },
    {
      name: 'Date',
      selector: (row) => row?.pay_date?.split(' ')[0] || 'N/A',
      sortable: true,
    },

    {
      name: 'Action',
      minWidth: '220px',
      cell: (row) => (
        <div>
          {/* <GrayButton
                        className="circle-btn mr-1"
                        onClick={() => handleAction(row)}
                    ><img src={Images.BarCode} alt="..." /></GrayButton> */}

          {/* <Link to={`/dashboard/inventory/supplier/show/${row.uid}`}>
            <SuccessButton
              className='circle-btn mr-1'
              onClick={() => handleAction(row)}
            >
              <Eye size={16} />
            </SuccessButton>
          </Link> */}
          {/* /dashboard/inventory/supplier-bill/edit/:id */}
          <Link to={`/dashboard/inventory/supplier-bill/edit/${row.uid}`}>
            <SuccessButton
              className='circle-btn mr-1'
              onClick={() => handleAction(row)}
            >
              <Edit2 size={16} />
            </SuccessButton>
          </Link>

          {/* <DangerButton
                        className="circle-btn"
                        onClick={() => {
                            setDelete({ value: row, show: true });
                            setSupplier(row);
                        }}
                    ><Trash2 size={16} />
                    </DangerButton> */}
        </div>
      ),
    },
  ];

  // Handle action
  const handleAction = (value) => setSupplier(value);

  // Handle search
  const handleSearch = async (data) => {
    try {
      setsearchLoading(true);
      const response = await Requests.Inventory.Supplier.DokanSupplierSearch(
        data
      );
      if (response.data) setData(response.data.data);
      setsearchLoading(false);
    } catch (error) {
      if (error) setsearchLoading(false);
    }
  };

  // Handle search suggestion
  const handleSuggestion = async (value) => {
    let data = {
      results: [],
      message: null,
    };

    try {
      const response = await Requests.Inventory.Supplier.DokanSupplierSearch(
        value
      );
      if (response && response.data.data && response.data.data.length) {
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          data.results.push(element.name);
        }
      } else {
        data.message = 'No results found';
      }
    } catch (error) {
      if (error) data.message = 'No results found';
    }

    return data;
  };

  const handleDelete = async () => {
    try {
      setDelete({ ...isDelete, loading: true });
      const res = await Requests.Inventory.Supplier.DokanSupplierDelete(
        parseInt(supplier.uid)
      );
      if (res.status === 200) fetchData();
      setDelete({ ...isDelete, show: false, loading: false });
      Toastify.Success('Successfully deleted.');
    } catch (error) {
      if (error) {
        setDelete({ ...isDelete, show: false, loading: false });
        Toastify.Error('Network error.');
      }
    }
  };

  return (
    <div>
      <Layout
        page='inventory / supplier Bill list'
        message='Bill / Supplier Bill Details for you.'
        container='container-fluid'
        printable
        printData={'No data'}
        otherPage
        pageLink='/dashboard/inventory/supplier-bill/list'
        button={
          <div className='print-hidden'>
            <Link to='/dashboard/inventory/supplier-bill/add'>
              <GrayButton type='button'>
                <Plus size={15} style={{ marginRight: 5 }} />
                <span style={{ fontSize: 13 }}>ADD SUPPLIER BILL</span>
              </GrayButton>
            </Link>
            <GrayButton
              type='button'
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDF}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      />
      <Main>
        {serverError ? <NetworkError message={t('Network error!')} /> : null}
        {!serverError ? (
          <Container.Fluid>
            <Container.Row>
              <Container.Column>
                <DataTable
                  columns={columns}
                  data={data}
                  loading={loading}
                  totalRows={totalRows}
                  handlePerRowsChange={handlePerRowsChange}
                  handlePageChange={handlePageChange}
                  noDataMessage='No supplier available'
                  searchable
                  placeholder={'Search Supplier'}
                  search={handleSearch}
                  suggestion={handleSuggestion}
                  searchLoading={searchLoading}
                  clearSearch={() => fetchData(1)}
                />
              </Container.Column>
            </Container.Row>
          </Container.Fluid>
        ) : null}
      </Main>

      {/* Delete confirmation modal */}
      <DeleteModal
        show={isDelete.show}
        loading={isDelete.loading}
        message={
          <h6>
            Want to delete {isDelete.value ? isDelete.value.name : null} ?
          </h6>
        }
        onHide={() => setDelete({ value: null, show: false, loading: false })}
        doDelete={handleDelete}
      />
    </div>
  );
};

export default Index;
