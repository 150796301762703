import { API } from '../../Api';
import axiosInstance from '../axiosInterceptor';

const DokanSupplierAdd = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/suppliers`, data, config);
};
const DokanSupplierBillAdd = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/supplier-payments`, data, config);
};

const DokanSupplierBillUpdate = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.put(
    `${API}/supplier-payments/${data.uid}`,
    data,
    config
  );
};
const DokanSupplierUpdate = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.put(`${API}/suppliers/${data.uid}`, data, config);
};

const DokanSupplierList = async (page, perpage) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  const uid = localStorage.getItem('dokanuid');
  return await axiosInstance.get(
    `${API}/suppliers?dokan_uid=${uid}&page=${page || 1}&per_page=${
      perpage || 10
    }`,
    config
  );
};
const DokanSupplierBillList = async (page, perpage) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  const uid = localStorage.getItem('dokanuid');
  return await axiosInstance.get(
    `${API}/supplier-payments?dokan_uid=${uid}&page=${page || 1}&per_page=${
      perpage || 10
    }`,
    config
  );
};

const DokanSupplierStockInHistory = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/suppliers/${uid}/stockin?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};
const DokanSupplierPaymentHistory = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/suppliers/${uid}/payments?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};
const DokanSupplierStockOutHistory = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/suppliers/${uid}/stockout?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};

const DokanSupplierBillShow = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/supplier-payments/${uid}?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}`,
    config
  );
};
const DokanSupplierShow = async (uid) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/suppliers/${uid}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const DokanSupplierDelete = async (data) => {
  const config = {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.delete(
    `${API}/suppliers/${data}?dokan_uid=${localStorage.getItem('dokanuid')}`,
    config
  );
};

const DokanSupplierSearch = async (search) => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/suppliers?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${search}`,
    config
  );
};

const Supplier = {
  DokanSupplierAdd,
  DokanSupplierBillList,
  DokanSupplierBillAdd,
  DokanSupplierBillUpdate,
  DokanSupplierBillShow,
  DokanSupplierUpdate,
  DokanSupplierList,
  DokanSupplierShow,
  DokanSupplierDelete,
  DokanSupplierSearch,
  DokanSupplierStockInHistory,
  DokanSupplierStockOutHistory,
  DokanSupplierPaymentHistory,
};

export default Supplier;
