import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NoContent } from '../../../components/204/NoContent';
import { NetworkError } from '../../../components/501/NetworkError';
import { Container } from '../../../components/container/Index';
import { DatePicker } from '../../../components/datePicker/Index';
import { FormGroup } from '../../../components/formGroup/FormGroup';
import { Layout, Main } from '../../../components/layout/Index';
import { Loader } from '../../../components/loading/Index';
import { SingleSelect } from '../../../components/select/Index';
import { DataTable } from '../../../components/table/Index';
import { Text } from '../../../components/text/Text';
import { useWindowSize } from '../../../components/window/windowSize';
import html2pdf from 'html2pdf.js';
import {
  dateYearFormat,
  formatDateWithAMPM,
  getCurrentDateTime,
} from '../../../utils/_heplers';
import axiosInstance from '../../../utils/Http/axiosInterceptor';
import { Requests } from '../../../utils/Http/Index';
import { PrimaryModal } from '../../../components/modal/PrimaryModal';
import {
  GrayButton,
  PrimaryButton,
  SuccessButton,
} from '../../../components/button/Index';
import { Eye, Printer } from 'react-feather';
import BrandImage from '../../../assets/logo.png';
import jsPDF from 'jspdf';
import moment from 'moment';

const Index = () => {
  const [data, setData] = useState([]);
  const [dokanData, setDokanData] = useState([]);
  const size = useWindowSize();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [searching, setSearching] = useState(false);
  const [error, setError] = useState(false);
  const [date, setDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [serverError, setServerError] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [invoiceData, setInvoiceData] = useState({});
  const [logoData, setLogoData] = useState();
  const [customer, setCustomer] = useState('');
  const [invoiceNo, setInvoiceNo] = useState('');
  const [isCreate, setCreate] = useState({ show: false, loading: false });
  const templateRef = useRef();
  const statusOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Processing', value: 'processing' },
    { label: 'Delivered', value: 'delivered' },
  ];

  // fetch data
  const fetchDokanData = useCallback(async () => {
    try {
      const response = await Requests.Settings.DokanSettingInformation();
      if (response.status === 200) setDokanData(response.data);
      setLogoData(response.data?.dokan?.logo);

      setLoading(false);
    } catch (error) {
      if (error) setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchDokanData();
  }, [fetchDokanData]);

  const handleGeneratePDFForList = () => {
    // jsPDF.API.registerFont("RobotoCondensedBold", RobotoCondensedBold);

    const pdf = new jsPDF();
    const columns = [
      'Invoice NO.',
      'Customer Name',
      'Phone',
      'Total',
      'Amount Paid',
      'Amount Due',
      'Payment Method',
    ];

    // Calculate the x-coordinate for the brand image to align it to the right
    const imageX = pdf.internal.pageSize.width - 30;

    // Add brand image on the right
    pdf.addImage(BrandImage, 'PNG', imageX, 10, 20, 20);

    const textX = imageX - 165; // Adjust the value based on your desired spacing
    const textY = 20;

    pdf.setFont('RobotoCondensedBold');
    pdf.setFontSize(15); // Set the font size
    pdf.text(textX, textY, 'CUSTOMER DATA');
    // Add another small text with a different font size

    const generatedOnText = `${getCurrentDateTime()}`;
    const smallTextSize = 10; // Set the font size for the small text
    pdf.setFontSize(smallTextSize);
    pdf.text(textX, textY + 6, generatedOnText);

    pdf.autoTable({
      startY: 40,
      head: [columns], // Headers
      body: data.map((row) => [
        row.invoice_no,
        row.customer_info ? row.customer_info.name : '',
        row.customer_info ? row.customer_info.phone_no : '',
        row.busket_grand_total ?? 0,
        row.amount_paid ?? 0,
        row.amount_due ?? 0,
        row.payment_method ? row.payment_method : 'N/A',
      ]),
      styles: {
        fillColor: [104, 126, 255], // RGB color for blue background
        font: 'RobotoCondensedBold',
      },
    });

    pdf.save('customer_data.pdf');
  };

  // const handleGeneratePDF = async () => {
  //   const element = templateRef.current; // Make sure templateRef points to the correct element

  //   const opt = {
  //     margin: 0.5,
  //     filename: 'order_invoice.pdf',
  //     image: { type: 'jpeg', quality: 0.98 },
  //     html2canvas: { scale: 2 },
  //     jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
  //   };

  //   // Ensure image is loaded before generating the PDF
  //   const img = new Image();
  //   img.src = dokanData?.dokan?.logo; // Logo URL
  //   img.crossOrigin = 'Anonymous'; // Ensure cross-origin issues don't block image loading

  //   // Wait for image to load
  //   img.onload = () => {
  //     // Add the image to the element before generating the PDF
  //     html2pdf()
  //       .from(element)
  //       .set(opt)
  //       .toPdf()
  //       .get('pdf')
  //       .then((pdf) => {
  //         // Add the logo image to the PDF after the content is loaded
  //         const imageX = pdf.internal.pageSize.width - 30; // Adjust X-position
  //         pdf.addImage(img, 'JPEG', imageX, 10, 20, 20); // Add the logo image at the desired position
  //       })
  //       .save();
  //   };

  //   img.onerror = (err) => {
  //     console.error('Image failed to load', err);
  //   };
  // };

  const handleGeneratePDF = async () => {
    const element = templateRef.current; // Reference to the HTML element
    await generateInvoiceNo();
    const opt = {
      margin: 0.5,
      filename: `${invoiceNo}-order_invoice.pdf`,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2 },
      jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' },
    };

    // Generate PDF from the element without loading any images
    html2pdf().from(element).set(opt).save();
  };

  const handlePageChange = (page) => {
    fetchData(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    setLoading(true);

    try {
      const response = await Requests.POS.OrderList(page, 10);
      setData(response.data.data);

      setTotalRows(response.data.data.length);
      // setTotalRows(response.data.tota);

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
    // setData(response.data.data)
    setPerPage(newPerPage);
    setLoading(false);
  };

  const fetchData = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await Requests.POS.OrderList(page, 10);
      setData(response.data.data);

      setTotalRows(response.data.data.length);
      // setTotalRows(response.data.tota);

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const generateInvoiceNo = async () => {
    try {
      const response = await Requests.POS.GenerateInvoice();
      setInvoiceNo(response?.data?.data?.invoice_number);
      // setTotalRows(response.data.tota);

      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  };

  const columns = [
    {
      name: 'Invoice NO.',
      selector: (row) => row.uid,
      sortable: true,
    },
    {
      name: 'Customer Name',
      selector: (row) => (row.customer_info ? row.customer_info.name : ''),
      sortable: true,
    },

    {
      name: 'Customer Phone',
      selector: (row) => (row.customer_info ? row.customer_info.phone_no : ''),
      sortable: true,
    },
    {
      name: 'Busket Grand Total',
      selector: (row) => row.busket_grand_total,
      sortable: true,
    },
    {
      name: 'Amount Paid',
      selector: (row) => row.amount_paid,
      sortable: true,
    },
    {
      name: 'Amount Due',
      selector: (row) => row.amount_due,
      sortable: true,
    },

    {
      name: 'Payment Method',
      selector: (row) => row.payment_method,
      sortable: true,
    },

    {
      name: 'Action',
      minWidth: '100px',
      cell: (row) => (
        <div>
          <GrayButton
            type='button'
            style={{ borderRadius: '50%', padding: '6px 9px', marginRight: 5 }}
            onClick={() => handleShowPopUp(row)}
          >
            <Printer size={16} />
          </GrayButton>
        </div>
      ),
    },
  ];

  // Handle action
  const handleShowPopUp = (value) => {
    setCreate({ show: true, loading: false });
    setInvoiceData(value);
    generateInvoiceNo();
  };

  // Handle search
  const handleSearch = async (query) => {
    setSearching(true);

    setTimeout(() => {
      setSearching(false);
    }, 2000);
  };

  const fetchOrderList = useCallback(
    async (fromDate, toDate) => {
      setLoading(true);
      const formattedDateFrom = fromDate
        ? moment(fromDate).format('YYYY-MM-DD')
        : '';
      const formattedDateTo = toDate ? moment(toDate).format('YYYY-MM-DD') : '';
      try {
        const response = await Requests.POS.OrderList(
          totalRows,
          perPage,
          formattedDateFrom,
          formattedDateTo,
          customer
        );
        setData(response.data.data);
        setTotalRows(response.data.total);
      } catch (error) {
        // Handle error
        console.error('Error fetching accounts with query:', error);
        // setError(true);
      } finally {
        setLoading(false);
      }
    },
    [date, toDate, perPage, totalRows, customer]
  );

  useEffect(() => {
    if (date || toDate || customer) {
      // If date and toDate are set, fetch with query parameters
      fetchOrderList(date, toDate, customer);
    } else {
      // Otherwise, fetch without query parameters
      fetchData();
    }
  }, [fetchData, fetchOrderList]);

  // fetch mechanics data
  const fetchCustomerData = useCallback(
    async (page) => {
      try {
        const response = await Requests.Customer.AllCustomer(page, perPage);
        if (response.status === 200) {
          // setMechanics(response.data.data)
          let data = [];
          if (response.data.data) {
            for (let i = 0; i < response.data.data.length; i++) {
              let elements = response.data.data[i];
              data.push({
                label: elements.name ?? '',
                value: elements.uid,
              });
            }
          }
          setCustomers(data);
        }
        setLoading(false);
      } catch (error) {
        if (error) {
          setLoading(false);
          setServerError(true);
        }
      }
    },
    [perPage]
  );

  // Mechanic Data Call
  useEffect(() => {
    fetchCustomerData(1);
  }, [fetchCustomerData]);

  return (
    <div>
      <Layout
        page='pos / order list'
        message='All orders you & your customers made is here.'
        container='container-fluid'
        printable
        printData={'No data'}
        otherPage
        pageLink='/dashboard/inventory/product/new'
        button={
          <div>
            <GrayButton
              type='button'
              className='ml-2 mt-2 mt-sm-0'
              onClick={handleGeneratePDFForList}
            >
              <Printer size={15} style={{ marginRight: 5 }} />
              <span style={{ fontSize: 13 }}>{t('PRINT')}</span>
            </GrayButton>
          </div>
        }
      >
        <Main>
          <Container.Column className='pr-4 mt-2 mb-2'>
            <div className='d-sm-flex justify-content-end pr-2'>
              {/* Mechanic options */}
              <div
                className='pr-sm-2 mb-2 mb-sm-0'
                style={{ width: size.width <= 576 ? '100%' : 200 }}
              >
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('Select Customer')}
                  </Text>
                  <SingleSelect
                    borderRadius={30}
                    placeholder='customer'
                    options={customers}
                    value={(event) => setCustomer(event.value)}
                  />
                </FormGroup>
              </div>

              {/* From Datepicker */}
              <div
                className='pr-sm-2 mb-2 mb-sm-0'
                style={{ width: size.width <= 576 ? '100%' : 160 }}
              >
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>
                    {t('From')}
                  </Text>
                  <DatePicker
                    className='rounded-pill'
                    selected={(data) => setDate(data)}
                    deafultValue={date}
                  />
                </FormGroup>
              </div>

              {/* To Datepicker */}
              <div style={{ width: size.width <= 576 ? '100%' : 160 }}>
                <FormGroup className='mb-0'>
                  <Text className='text-capitalize fs-13 mb-1'>{t('To')}</Text>
                  <DatePicker
                    className='rounded-pill'
                    selected={(data) => setToDate(data)}
                    deafultValue={toDate}
                  />
                </FormGroup>
              </div>
            </div>
          </Container.Column>
          <Container.Column>
            {loading && !data.length ? <Loader /> : null}
            {!loading && error && !data.length ? (
              <NetworkError message='Network Error.' />
            ) : !loading && !data.length ? (
              <NoContent message='No Content.' />
            ) : (
              <DataTable
                columns={columns}
                data={data}
                loading={loading}
                totalRows={totalRows}
                handlePerRowsChange={handlePerRowsChange}
                handlePageChange={handlePageChange}
                search={handleSearch}
                searching={searching}
              />
            )}
          </Container.Column>
        </Main>
      </Layout>

      <PrimaryModal
        show={isCreate.show}
        onHide={() => setCreate({ show: false, loading: false })}
        title={t(`Download Invoice- ${invoiceNo}`)}
        size='md'
      >
        <div className='text-center'>
          <PrimaryButton
            type='submit'
            className='px-4'
            // disabled={props.loading}
            onClick={handleGeneratePDF}
          >
            Download
          </PrimaryButton>
        </div>
      </PrimaryModal>
      <div className='d-none'>
        {invoiceData && dokanData && (
          <div
            ref={templateRef}
            style={{
              fontFamily: 'Arial, sans-serif',
              fontSize: '13px',
              padding: '20px 60px',
              color: '#414141',
            }}
          >
            {/* Shop Name Section Centered */}
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginBottom: '20px',
                textAlign: 'center',
                fontSize: '12px',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '100%' }}>
                    <img
                      src={
                        'https://img.freepik.com/free-vector/bird-colorful-logo-gradient-vector_343694-1365.jpg?size=626&ext=jpg&ga=GA1.1.1568320668.1726126838&semt=ais_hybrid'
                      }
                      alt='Shop Logo'
                      style={{ width: '80px', height: 'auto' }}
                    />

                    <p style={{ margin: '5px 0 0' }}>
                      {dokanData?.dokan?.title}
                    </p>
                    <p style={{ margin: '5px 0 0' }}>Phone number</p>
                    <p style={{ margin: '5px 0 0' }}>Address</p>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Customer Name and Invoice No Section Aligned in a Single Row */}
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginBottom: '20px',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '50%', fontWeight: 'bold' }}>
                    CUSTOMER NAME:
                  </td>
                  <td
                    style={{
                      width: '50%',
                      textAlign: 'right',
                      fontWeight: 'bold',
                    }}
                  >
                    INVOICE NO:
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '5px' }}>
                    {invoiceData?.customer_info?.name}
                  </td>
                  <td style={{ paddingTop: '5px', textAlign: 'right' }}>
                    {invoiceNo}
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingTop: '5px' }}>
                    {' '}
                    {invoiceData?.customer_info?.phone}
                  </td>
                  <td style={{ paddingTop: '5px', textAlign: 'right' }}>
                    {invoiceData?.purchase_date?.split(' ')[0]}
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Invoice Table */}
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginBottom: '20px',
                textAlign: 'left',
              }}
            >
              <thead
                style={{
                  borderBottom: '2px solid gray',
                  borderTop: '2px solid gray',
                }}
              >
                <tr>
                  <th style={{ padding: '10px', backgroundColor: '#f9f9f9' }}>
                    DESCRIPTION
                  </th>
                  <th style={{ padding: '10px', backgroundColor: '#f9f9f9' }}>
                    PRODUCT CODE
                  </th>
                  <th style={{ padding: '10px', backgroundColor: '#f9f9f9' }}>
                    WARRANTY PERIOD
                  </th>
                  <th style={{ padding: '10px', backgroundColor: '#f9f9f9' }}>
                    QTY
                  </th>
                  <th style={{ padding: '10px', backgroundColor: '#f9f9f9' }}>
                    TOTAL
                  </th>
                </tr>
              </thead>
              <tbody>
                {invoiceData?.items?.map((item) => {
                  return (
                    <tr>
                      <td style={{ padding: '10px' }}>{item?.product?.name}</td>
                      <td style={{ padding: '10px' }}>
                        {item?.product?.product_code}
                      </td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>
                        {item?.warranty_period}-{item.warranty_type}
                      </td>
                      <td style={{ padding: '10px', textAlign: 'center' }}>
                        {item?.quantity}
                      </td>
                      <td style={{ padding: '10px', textAlign: 'right' }}>
                        ৳ {item?.unit_price}
                      </td>
                    </tr>
                  );
                })}

                {/* <tr>
                  <td style={{ padding: '10px' }}>Item 2</td>
                  <td style={{ padding: '10px' }}>21122121</td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>
                    1 Yrs
                  </td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>1</td>
                  <td style={{ padding: '10px', textAlign: 'right' }}>৳100</td>
                </tr>
                <tr>
                  <td style={{ padding: '10px' }}>filename=tryhtml_editor</td>
                  <td style={{ padding: '10px' }}>4645</td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>
                    1 Yrs
                  </td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>1</td>
                  <td style={{ padding: '10px', textAlign: 'right' }}>৳100</td>
                </tr>
                <tr>
                  <td style={{ padding: '10px' }}>Item 4</td>
                  <td style={{ padding: '10px' }}>645654</td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>
                    1 Yrs
                  </td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>1</td>
                  <td style={{ padding: '10px', textAlign: 'right' }}>৳100</td>
                </tr>
                <tr>
                  <td style={{ padding: '10px' }}>Item 5</td>
                  <td style={{ padding: '10px' }}>798789</td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>
                    1 Yrs
                  </td>
                  <td style={{ padding: '10px', textAlign: 'center' }}>1</td>
                  <td style={{ padding: '10px', textAlign: 'right' }}>৳100</td>
                </tr> */}
              </tbody>
              <tfoot
                style={{
                  borderTop: '2px solid gray',
                  borderBottom: '2px solid gray',
                }}
              >
                <tr>
                  <td
                    colSpan='4'
                    style={{ padding: '10px', fontWeight: 'bold' }}
                  >
                    TOTAL
                  </td>
                  <td
                    style={{
                      padding: '10px',
                      textAlign: 'right',
                      fontWeight: 'bold',
                    }}
                  >
                    <span className='d-flex justify-content-end'>
                      <span className='mr-2'> ৳ </span>
                      <span>{invoiceData?.busket_total} </span>
                    </span>
                  </td>
                </tr>
              </tfoot>
            </table>

            {/* Summary Section */}
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                marginBottom: '20px',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ width: '50%' }}></td>
                  <td style={{ width: '50%', textAlign: 'right' }}>
                    <table
                      style={{ width: '100%', borderCollapse: 'collapse' }}
                    >
                      <tbody>
                        <tr>
                          <td style={{ padding: '5px 10px' }}>VAT/GST</td>
                          <td
                            style={{
                              padding: '5px 10px',
                              textAlign: 'right',
                            }}
                          >
                            0
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px 10px' }}>Discount</td>
                          <td
                            style={{
                              padding: '5px 10px',
                              textAlign: 'right',
                            }}
                          >
                            <span className='d-flex justify-content-end'>
                              <span className='mr-2 '> ৳ </span>
                              <span>{invoiceData?.special_discount} </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px 10px' }}>Sub Total</td>
                          <td
                            style={{
                              padding: '5px 10px',
                              textAlign: 'right',
                            }}
                          >
                            <span className='d-flex justify-content-end'>
                              <span className='mr-2 '> ৳ </span>
                              <span>{invoiceData?.grand_total} </span>
                            </span>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ padding: '5px 10px' }}>Amount Paid</td>
                          <td
                            style={{
                              padding: '5px 10px',
                              textAlign: 'right',
                            }}
                          >
                            <span className='d-flex justify-content-end'>
                              <span className='mr-2'> ৳ </span>
                              <span>{invoiceData?.amount_paid} </span>
                            </span>
                          </td>
                        </tr>

                        <tr
                          style={{
                            borderBottom: '1px solid gray',
                            borderTop: '1px solid gray',
                          }}
                        >
                          <td
                            style={{
                              padding: '5px 10px',
                              fontWeight: 'bold',
                            }}
                          >
                            Total Due
                          </td>
                          <td
                            style={{
                              padding: '5px 10px',
                              textAlign: 'right',
                              fontWeight: 'bold',
                            }}
                          >
                            <span className='d-flex justify-content-end'>
                              <span className='mr-2'> ৳ </span>
                              <span>{invoiceData?.amount_due} </span>
                            </span>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </td>
                </tr>
              </tbody>
            </table>

            {/* Footer */}
            <p style={{ textAlign: 'center', marginTop: '30px' }}>
              Thank You For Shopping with{' '}
              <strong>{dokanData?.dokan?.title}</strong>
            </p>
            <hr
              style={{
                border: 'none',
                borderTop: '1px solid #ddd',
                margin: '20px 0',
              }}
            />
            <table
              style={{
                width: '100%',
                borderCollapse: 'collapse',
                fontSize: '11px',
              }}
            >
              <tbody>
                <tr>
                  <td style={{ textAlign: 'left' }}>
                    Sold by {dokanData?.user?.name}
                  </td>
                  <td style={{ textAlign: 'right' }}>
                    Powered by www.onvirtualworld.com
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Index;
