import { API } from '../../Api';
import axiosInstance from '../axiosInterceptor';

// for list of all Daily payable
const DailyPayableIndex = async (perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/daily-payables/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}`,
    config
  );
};

// for Daily payable index search
const DailyPayableSearch = async (query) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.get(
    `${API}/daily-payables/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&q=${query}`,
    config
  );
};

// Daily payable index filter by from date
const FilterByFromDate = async (formDate, perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/daily-payables/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}&from=${formDate}`,
    config
  );
};

// Daily payable index filter by from and to date
const FilterByFromToDate = async (formDate, toDate, perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/daily-payables/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}&from=${formDate}&to=${toDate}`,
    config
  );
};

// for daily payable create
const DailyPayableCreate = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/daily-payables`, data, config);
};

// for ledger create
const LedgerCreate = async (data) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };

  return await axiosInstance.post(`${API}/ledgers`, data, config);
};

// for list of all ledger
const LedgerIndex = async (perpage) => {
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  };
  return await axiosInstance.get(
    `${API}/ledgers/?dokan_uid=${localStorage.getItem(
      'dokanuid'
    )}&per_page=${perpage}`,
    config
  );
};

const DailyPayable = {
  DailyPayableIndex,
  DailyPayableSearch,
  FilterByFromDate,
  FilterByFromToDate,
  LedgerCreate,
  LedgerIndex,
  DailyPayableCreate,
};

export default DailyPayable;
