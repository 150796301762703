import Logo from '../assets/logo.png';
import DefaultLogo from '../assets/default.png';
import FourOFour from '../assets/404.png';
import BarCode from '../assets/barcode.png';
import NetworkError from '../assets/501.png';
import NoContent from '../assets/204.png';
import EmptyCart from '../assets/emptycart.svg';
import ComingSoon from '../assets/coming-soon.jpg';

import Radoan from '../assets/chat/radoan.jpg';
import Pritom from '../assets/chat/pritom.jpg';
import Anik from '../assets/chat/anik.jpg';
import Pronab from '../assets/chat/pronab.jpg';
import Aziz from '../assets/chat/aziz.jpg';
import Shahed from '../assets/chat/shahed.jpg';
import Neloy from '../assets/chat/neloy.jpg';
import Mamun from '../assets/chat/mamun.jpg';
import Money from '../assets/money.png';

export const Images = {
  Logo,
  FourOFour,
  BarCode,
  NetworkError,
  NoContent,
  EmptyCart,
  DefaultLogo,
  Radoan,
  Pritom,
  Anik,
  Pronab,
  Aziz,
  Shahed,
  Neloy,
  Mamun,
  Money,
  ComingSoon,
};
