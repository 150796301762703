import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { NoContent } from '../../../components/204/NoContent';
import { NetworkError } from '../../../components/501/NetworkError';
import { Busket } from '../../../components/busket/Index';
import { PrimaryButton } from '../../../components/button/Index';
import { Container } from '../../../components/container/Index';
import { Layout, Main } from '../../../components/layout/Index';
import { Loader } from '../../../components/loading/Index';
import { Product } from '../../../components/product/Index';
import { useQuery } from '../../../components/query/Index';
import { Requests } from '../../../utils/Http/Index';
import {
  addToDatabaseCart,
  getDatabaseCart,
  removeFromDatabaseCart,
} from '../../../utils/utilities';
import { Toastify } from '../../../components/toastify/Toastify';

const Pos = () => {
  const { t } = useTranslation();
  const onClearRef = useRef();
  const history = useHistory();
  const queryParams = useQuery();
  const [productData, setProductData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [busket, setBusket] = useState([]);

  const [product, setProduct] = useState([]);

  const savedCart = getDatabaseCart();
  const qnt = Object.keys(savedCart).length;
  const [changedId, setChangedId] = useState(null);
  const [quantity, setQuantity] = useState(qnt ?? 0);
  const [busketproduct, setBusketProd] = useState([]);
  const [busketShake, setBusketShake] = useState(false);
  const [closeFilter, setCloseFilter] = useState(false);

  const [filterNameBarcode, setFilterNameBarcode] = useState('');

  // for shaking busket of pos page
  const handleBusketShake = (product) => {
    console.log('handleBusketShake:43', product);

    handleChangeInputBusket(product.uid);
    setBusketShake(true);

    fetchProduct();

    setTimeout(() => {
      setBusketShake(false);
    }, 300);
  };

  // for fetching product list
  const fetchData = useCallback(async (page) => {
    setLoading(true);
    try {
      const response = await Requests.Inventory.Product.DokanPosProductList(
        page,
        10
      );
      console.log('response.data.data', response.data.data);
      setProductData(response.data.data);
      setLoading(false);
      setError(false);
    } catch (error) {
      setLoading(false);
      setError(true);
    }
  }, []);

  const refreshProductList = useCallback(async () => {
    fetchData(1);
  }, []);

  // ------------------------ Filter item ---------------
  const handleFilterByUrl = (field, value) => {
    let item = { [field]: value };
    let params = {
      ...queryParams,
      ...item,
    };

    if (params) {
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      history.replace(`point-of-sell?${queryString}`);
      setCloseFilter(true);
    }
  };

  // handle filter category
  const handleFilterCategory = async (data) => {
    let results = [];
    try {
      const response = await Requests.Inventory.Category.CategorySearch(
        data.query
      );
      if (response.data && response.data.data && response.status === 200) {
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          results.push({
            value: element.uid,
            label: element.name,
          });
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        console.log('No Category found');
      }
    }
    return results;
  };

  // handle filter brand
  const hadleFIlterBrand = async (data) => {
    let results = [];
    try {
      const response = await Requests.Inventory.Brand.DokanBrandSearch(
        data.query
      );
      if (response.data && response.data.data && response.status === 200) {
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          results.push({
            value: element.uid,
            label: element.name,
          });
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        console.log('No Brand Found');
      }
    }
    return results;
  };

  // handle fitler product
  const handleFilterProduct = async (data) => {
    let results = [];
    try {
      const response = await Requests.Inventory.Product.DokanPosProductSearch(
        data.query
      );
      if (response.data && response.data.data && response.status === 200) {
        for (let i = 0; i < response.data.data.length; i++) {
          const element = response.data.data[i];
          results.push({
            value: element.uid,
            label: element.name,
          });
        }
      }
    } catch (error) {
      if (error && error.response && error.response.status === 404) {
        console.log('No Brand Found');
      }
    }
    return results;
  };

  const onSearchProductSubmit = async (e) => {
    e.preventDefault();

    if (!filterNameBarcode) return;

    try {
      const response = await Requests.Inventory.Product.DokanPosProductSearch(
        filterNameBarcode
      );
      if (response.data && response.data.data && response.status === 200) {
        setProductData(response.data.data);
        setLoading(false);
        setError(false);
      }
    } catch (error) {
      // if (error && error.response && error.response.status === 404) {
      //   // console.log("No Brand Found");
      // }

      setLoading(false);
      setError(true);
    }
  };

  // discount calculation
  const discountCalculate = (corePrice, discountType, discountAmount) => {
    let newPrice;

    if (discountType && discountType === 'taka') {
      newPrice = parseInt(corePrice) - parseInt(discountAmount);
    } else {
      const discount = (corePrice * discountAmount) / 100;
      newPrice = parseInt(corePrice - discount);
    }

    return newPrice;
  };

  // handle Busket
  const handleBusket = (product, price) => {
    const main_product = { ...product };

    const toBeAddedKey = main_product.uid;

    const sameProduct =
      busket.length > 0 &&
      busket.find((productItem) => productItem.uid === toBeAddedKey);

    let count = 1;
    let newCart;

    if (sameProduct) {
      Toastify.Error('Product Already in Busket!');

      //   count = sameProduct.quantity;
      //   count = sameProduct.quantity;

      //   sameProduct.quantity = sameProduct.quantity;

      //   const others = busket.filter(
      //     (productItem) => productItem.uid !== toBeAddedKey
      //   );

      //   newCart = [...others, sameProduct];

      newCart = [...busket];
    } else {
      main_product.product_quantity = main_product.quantity;

      //   main_product.quantity = 1;
      newCart = [...busket, main_product];
    }

    const selling_price = discountCalculate(
      main_product.selling_price,
      main_product.discount_type,
      main_product.discount_amount
    );

    addToDatabaseCart(
      JSON.stringify({
        uid: main_product.uid,
        name: main_product.name,
        warranty_period: main_product.warranty_period,
        discount_type: main_product.discount_type,
        price: isNaN(price) !== true ? price : selling_price,
        featured_image: main_product.featured_image,
        product_quantity: sameProduct
          ? sameProduct.product_quantity
          : main_product.quantity,
        is_code_separate: main_product.is_code_separate == 1 ? 1 : 0,
        code_uid: main_product.code_uid ? main_product.code_uid : '',
      }),
      count
    );

    setBusket(newCart);
  };

  // for busket
  const handleChangeInputBusket = (id, event = null, types = null) => {
    const newInputFields = product.map((item) => {
      setChangedId(id);
      if (id === item.value) {
        item['product_uid'] = item.value;
        if (types === 'price') {
          item['price'] = event.target.value;
        }
        if (types === 'quantity') {
          item['quantity'] = event.target.value;
        }
        if (types === 'warrenty') {
          item['warrenty'] = event.target.value ?? 0;
        }
      }

      return item;
    });
    setBusketProd(newInputFields);

    console.log('handleChangeInputBusket:242');
    console.log('newInputFields', newInputFields);
  };

  // fetch product
  const fetchProduct = useCallback(async () => {
    const product = [];
    Object.keys(getDatabaseCart()).map((item) => {
      const item2 = JSON.parse(item);
      product.push({
        value: item2.uid,
        label: item2.name,
        price: item2.price,
        quantity: 1,
        warrenty: parseInt(item2.warranty_period) ?? 0,
        product_quantity: item2.product_quantity,
        is_code_separate: item2.is_code_separate == 0 ? 0 : 1,
        code_uid: item2.code_uid ? item2.code_uid : '',
      });
      return product;
    });
    setBusketProd(product);
    setProduct(product);
  }, []);

  // for deleting quantity
  const handleDeleteQuantity = (data) => {
    removeFromDatabaseCart(data);
    setQuantity(quantity - 1);
    fetchProduct();
  };

  useEffect(() => {
    if (queryParams) {
      let params = { ...queryParams };
      const queryString = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
      fetchData(queryString);
      setCloseFilter(true);
    }
    fetchProduct();
  }, [
    queryParams.product,
    queryParams.category,
    queryParams.brand,
    fetchData,
    fetchProduct,
  ]);

  return (
    <div>
      <Layout
        page='pos / point of sell'
        message={t('Product From Your Store.')}
        shorttext='All Products from your store is here'
        container='container-fluid'
      />

      <Main>
        {loading && !productData.length ? <Loader /> : null}

        {!loading && error && !productData.length ? (
          <NetworkError message='Network Error.' />
        ) : (
          <>
            <Container.Fluid>
              <form onSubmit={onSearchProductSubmit}>
                <Container.Row>
                  <Container.Column className='col-xl-5 col-lg-4 md-sm-2 mb-2'>
                    {/* <SearchableSelect
                      refs={onClearRef}
                      placeholder="Enter product name/barcode/imei"
                      isClearable={true}
                      defaultValue={
                        queryParams.product
                          ? {
                              label: queryParams.product,
                              value: queryParams.product,
                            }
                          : null
                      }
                      search={(query) =>
                        handleFilterProduct({ query, field: "product" })
                      }
                      values={(event) =>
                        handleFilterByUrl("product", event.label)
                      }
                    /> */}

                    {/* Enter product name/barcode/imei */}

                    <input
                      type='text'
                      className='form-control shadow-none'
                      placeholder={t('Enter product name/barcode/imei')}
                      onChange={(e) => {
                        setFilterNameBarcode(e.target.value);
                      }}
                    />
                  </Container.Column>

                  <Container.Column className='col-xl-3 col-lg-3 md-sm-3 text-start mb-2'>
                    <PrimaryButton
                      type='submit'
                      className='px-4'
                      // disabled={loading}
                    >
                      <span>Search</span>
                    </PrimaryButton>
                  </Container.Column>
                </Container.Row>
              </form>

              {!loading && !productData.length ? (
                <NoContent message='No Content.' />
              ) : (
                <>
                  {/* <Container.Column className="col-xl-3 col-lg-4 md-sm-2 mb-2">
                  <SearchableSelect
                    refs={onClearRef}
                    placeholder="Category"
                    isClearable={true}
                    defaultValue={
                      queryParams.category
                        ? {
                            label: queryParams.category,
                            value: queryParams.category,
                          }
                        : null
                    }
                    search={(query) =>
                      handleFilterCategory({ query, field: "category" })
                    }
                    values={(event) =>
                      handleFilterByUrl("category", event.label)
                    }
                  />
                </Container.Column>
                <Container.Column className="col-xl-3 col-lg-4 md-sm-2 mb-2">
                  <SearchableSelect
                    refs={onClearRef}
                    placeholder="Brand"
                    isClearable
                    defaultValue={
                      queryParams.brand
                        ? { label: queryParams.brand, value: queryParams.brand }
                        : null
                    }
                    search={(query) =>
                      hadleFIlterBrand({ query, field: "brand" })
                    }
                    values={(event) => handleFilterByUrl("brand", event.label)}
                  />
                </Container.Column> */}

                  <Container.Row>
                    <Container.Column className='pb-5 pt-4'>
                      {productData.map((item, index) => {
                        return (
                          <Product
                            key={index}
                            product={item}
                            busketShake={handleBusketShake}
                            // busketShake={()=>{}}
                            // handleProduct={()=>{}}
                            handleProduct={handleBusket}
                          />
                        );
                      })}
                    </Container.Column>
                  </Container.Row>
                </>
              )}
            </Container.Fluid>
            <Busket
              // new two
              busketShake={handleBusketShake}
              handleProduct={handleBusket}


              busket={busketShake}
              fetchProduct={fetchProduct}
              product={product}
              handleDeleteQuantity={handleDeleteQuantity}
              qnt={qnt}
              handleChangeInputBusket={handleChangeInputBusket}
              busketproduct={busketproduct}
              productid={changedId}
              refreshProductList={() => {
                refreshProductList();
              }}
            />
          </>
        )}
      </Main>
    </div>
  );
};

export default Pos;
